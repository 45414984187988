<template>
  <div>
    <div>
      <img alt="Logo Auchan" width="200" src="@/assets/img/logo-auchan.png" />
    </div>
    <div>
      <img alt="Logo Auchan" width="200" class="center" src="@/assets/logos/dataObs.png" />
    </div>
    <div class="versions">
      <div class="v v2">
        {{ $t('home.infoV2') }}
        <div class="button">
          <a v-if="getUserInfos.user.displayName != null" href="/V2/infos" class="version">{{ $t('home.toAccess') }}</a>
          <a v-else href="/V2/rvf" class="version">{{ $t('home.toAccess') }}</a>
          <span class="version-name">V2</span>
        </div>
      </div>

      <div class="v v1">
        {{ $t('home.infoV1') }}
        <div class="button">
          <a v-if="getUserInfos.user.displayName != null" href="/supplierSearch" class="version">{{ $t('home.toAccess') }}</a>
          <a v-else href="/rvf" class="version">{{ $t('home.toAccess') }}</a>
          <span class="version-name">V1</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'Home',
    components: {},
    computed: {
      ...mapGetters('global', ['userInfos', 'userRoles']),

      getUserInfos() {
        return { user: this.userInfos, roles: this.userRoles }
      }
    },
    methods: {
      ...mapActions('supplier', ['fetchSuppliers']),
      ...mapActions('supplier', ['fetchSuppliersInfos'])
    },
    async mounted() {
      this.fetchSuppliersInfos()
    }
  }
</script>
<style lang="scss">
  .versions {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .v1 {
      width: 20em;
      font-size: 12px;
      padding: 15px 30px;
    }
    .v2 {
      box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.5);
    }
  }
  .version {
    background: linear-gradient(to bottom, #7892c2 5%, #476e9e 100%);
    border-radius: 10px;
    border: 1px solid #4e6096;
    cursor: pointer;
    font-family: Arial;
    padding: 9px 11px;
    text-shadow: 0px 1px 0px #283966;
    background: #ff6347 !important;
    color: white !important;
    border-radius: 100px;
    font-weight: bold;
    border: 2px solid white;
    text-transform: none;
  }
  .version:hover {
    background: linear-gradient(to bottom, #476e9e 5%, #7892c2 100%);
    background-color: #476e9e;
  }
  .version:active {
    position: relative;
    top: 1px;
  }
  a {
    color: white;
  }

  .v {
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6 !important;
    font-size: 16px;
    margin: 57px 0 15px 0;
    color: #333333;
    font-weight: 600;
    padding: 25px 40px;
    width: 27em;
    .button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 15px 0 0;
      .version-name {
        font-weight: bold;
        color: $primary;
      }
    }
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
</style>
