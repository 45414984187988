<template>
  <div class="modal-container" v-if="showModal">
    <div class="overlay" @click="$emit('close-modal')"></div>
    <div class="modal">
      <div class="flex justify-space">
        <h2 class="text-darkblue">{{ $t('MODAL.DELETE_CONFIRM') }}</h2>
        <v-svg class="icon" name="cross" :size="'16px'" @click="$emit('close-modal')" />
      </div>
      <div class="flex justify-end">
        <button class="bouton" @click="$emit('confirm', true)">{{ $t('OK') }}</button>
        <button class="bouton" @click="$emit('close-modal')">{{ $t('CANCEL') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg'
import VButton from '@/components/Button.vue'

export default {
  name: 'VConfirmModal',
  components: {
    VSvg,
    VButton
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="scss">
@import "~@/styles/_colors";

.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  background: $background;
  color: #333;
  position: fixed;
  top: 30%;
  width: 40rem;
  padding: 1rem 2rem;
}

.bouton {
  color: $maingreen;
  width: 80px;
  height: 40px;
  border: 0;
  margin: 0.5em;

  &:hover {
    background-color: $lightgrey;
  }
} 
</style>
