<template>
  <div>
    <v-svg class="icon" size="12px" :name="iconName" />
  </div>
</template>

<script>
  export default {
    name: "iconCellRenderer",
    data() {
      return {
        iconName: '',
      }
    },
    created() {
      this.iconName = this.params.iconName
    },
  }
</script>

<style scoped>
  .svg {
    height: 150%;
  }
</style>
