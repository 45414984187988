<template>
  <div class="supplier-detail-card-container">
    <div class="card-header ">
      <div class="flex justify-space align-center">
        <strong>{{ `${setting.market} - ${setting.orderSupplierCode} - ${setting.orderNegotiationGroupCode}` }}</strong>
        <div class="flex align-end">
          <v-button :icon="'eye'" @click="$emit('show-setting', setting)" />
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="flex">
        <tag :tag="setting.sendmode" />
        <tag v-for="(extraction, index) in formattedExtractionsTypes" :key="index" :tag="extraction" />
      </div>

      <div class="body-content flex wrap">

        <div class="w-100 flex mt-2">
          <div class="body-line-left" >
            <span class="text-teal text-bold">{{ $t('SUPPLIER_DETAIL.CNUF_REF') }}: </span>
          </div>
          <div class="body-line-right">
            <span>{{ setting.orderSupplierCode }} - {{ setting?.orderSupplierLabel  }}</span>
          </div>
        </div>

        <div class="w-100 flex mt-2">
          <div class="body-line-left" >
            <span class="text-teal text-bold">{{ $t('SUPPLIER_DETAIL.GDN_REF') }}: </span>
          </div>
          <div class="body-line-right">
            <span>{{ setting.orderNegotiationGroupCode }} - {{ setting?.negotiationGroupLabel  }}</span>
          </div>
        </div>

        <div class="w-100 flex mt-2">
          <div class="body-line-left" >
            <span class="text-teal text-bold">{{ $t('MARKET') }}: </span>
          </div>
          <div class="body-line-right">
            <span>{{ setting.market }} - {{ setting?.marketLabel  }}</span>
          </div>
        </div>

        <div class="w-100 flex mt-2">
          <div class="body-line-left">
            <span class="text-teal text-bold">{{ $t('SUPPLIER_DETAIL.FREQUENCY.TITLE') }}: </span>
          </div>
          <div class="body-line-right">
            <span>{{ $t(`SUPPLIER_DETAIL.FREQUENCY.${setting.frequency}`) }}</span>
          </div>

        </div>

        <div class="w-100 flex mt-2" v-if="setting.perimeter && !setting.sendmode">
          <div  class="body-line-left">
            <span class="text-teal text-bold">{{ $t('SUPPLIER_DETAIL.CONSOLIDATION.TITLE') }}: </span>
          </div>
          <div class="body-line-right">
            <span>{{ $t(`SUPPLIER_DETAIL.CONSOLIDATION.${setting.perimeter}`) }}</span>
          </div>
        </div>

        <div v-if="setting.typofextraction === 'V' && !setting.sendmode" class="w-100 flex mt-2">
          <div class="body-line-left" >
            <span class="text-teal text-bold">{{ $t('SUPPLIER_DETAIL.AVERAGE_SALES_PRICE.TITLE') }}: </span>
          </div>
          <div class="body-line-right">
            <span>{{ setting.ispurchasepriceaveragecolumn ? $t('SUPPLIER_DETAIL.AVERAGE_SALES_PRICE.O') : $t('SUPPLIER_DETAIL.AVERAGE_SALES_PRICE.N') }}</span>
          </div>
        </div>


      </div>

    </div>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg'
import Tag from '@/components/Tag'
import VButton from '@/components/Button'
import { formatDate } from '../../utils/utils'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'SupplierDetailCard',
  components: {
    VSvg,
    Tag,
    VButton
  },
  props: {
    setting: {
      type: Object,
      default: () => { }
    },
  },
  computed: {
    ...mapGetters('nomenclature', ['markets']),

    formattedExtractionsTypes() {
      return this.setting?.typofextraction?.split(',')
    }
  },
  data() {
    return {
      tags: [{ label: 'EDI', class: 'EDI' }, { label: 'S', class: 'S' }, { label: 'V', class: 'V' }],
    }
  },
  methods: {
    ...mapActions('nomenclature', ['fetchMarkets']),
    formatDate(date){
      return moment(date).format("DD-MM-YYYY")
    },
    beforeEnter: function (el) {
      el.style.height = '0'
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave: function (el) {
      el.style.height = '0'
    },
    showCard() {
      this.show = !this.show
      this.$emit('showCard', this.show)
    }
  }
}
</script>

<style lang="scss">
.supplier-detail-card-container {
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.29);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.29);
  background-color: white;
  border-radius: 3px;

  // padding: 2em 0.5em 2em 2em;
  margin: 0.5em;
  font-size: .7em;
  max-width: 24%;

  .card-title {
    margin-top: 2em;
  }

  .card-body {
    padding: 1em;
    width: 100%;
  }

  .card-header {
    padding: 0.3em 0.3em 0.3em 0.9em;

    border-bottom: 1px solid $lightgrey;
  }

  .body-line {
    padding-top: 2em;
  }
  .body-line-right {
    widows: 54%;
    line-height: 13px;
  }
  .body-line-left {
    width: 45%;
    line-height: 13px;
  }
}

.body {
  &-content {
    &-markets {
      overflow: auto;
      min-height: 60px;
    }
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul {
  position: relative;
  list-style-type: none;
  -webkit-padding-start: 20px;

}

li::before {
  content: '>';
  position: absolute;
  left: 0;
  color: $maingreen;
}
.lowercase {
  text-transform: lowercase;
}
</style>
