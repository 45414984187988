<template>
  <div class="label opacity" :class="class" @click="$emit('action')">
    <label class="text-bold ml-1">{{ label }}</label>
    <v-svg class="icon" size="12px" name="cross"></v-svg>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg'

export default {
  name: 'VLabel',
  components: {
    VSvg
  },
  props: {
    /**
     * label's text
    */
    label: {
      type: String,
      default: ''
    },
    class: {
      type: String,
      default: ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/styles/_colors";

.label {
  border-radius: 30px;
  padding: 0.4em 1em 0.3em 0.3em;
  margin: 0.3em;
  height: fit-content;
  width: fit-content;
  text-align: center;
  background-color: $lightgrey;
  color: black;

  &.supplier {
    border-bottom: 2px solid rgb(0, 150, 136) !important;
  }

  &.rayon {
    border-bottom: 2px solid rgb(20, 178, 218) !important;
  }

  &.paymentLabel {
    border-bottom: 2px solid #f90 !important;
  }

  .icon:first-child {
    margin-right: 0.5em;
  }

  .icon {
    margin-left: 1em;
    font-size: 5px;
    color: black;
  }

  .label {
    padding: 0 0.5em;
  }


}
</style>
