<template>
    <div v-show="isLoading" class="spinner">
      <div class="lds-dual-ring"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Loader',
    props: {
      isLoading: {
        default: false
      } 
    }
  }
  </script>
  
  <style scoped lang="scss">
  .spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: hsla(0, 0%, 100%, 0.6);
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 3;
  
    > p {
      color: $auchan-secondary;
      font-weight: bold;
    }
  }
  
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $primary;
    border-color: $primary transparent $primary transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>
  