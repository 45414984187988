<template>
  <div class="supplier-detail">
    <Loader :is-loading="loading" />
    <div class="supplier-detail-header" v-if="settings?.length">
      <div class="body-title flex justify-space align-center">
        <div class="text">{{ $t('FROM') }} <strong>{{ getStartDate }}</strong> {{ $t('TO') }} <strong>{{ getEndDate }}</strong> ({{ settings.length }} {{ $t('SUPPLIER_DETAIL.SETTING', settings.length) }})</div>
        <span class="text"> <strong class="mr-1">{{ settings[0].rayon }} </strong>-<strong class="ml-1 mr-1">{{ settings[0].paymentSupplierCode }} _ {{ settings[0].paymentSupplierLabel }} </strong>-<strong class="ml-1"> {{ settings[0].paymentNegotiationGroupCode }}</strong></span>
      </div>
    </div>
    <div class="body-content pb-3 scrollbar" v-if="settings?.length && !loading">
      <div class="body-cards flex ">
        <supplier-detail-card
          class="card"
          v-for="(setting, index) in settings"
          :key="index"
          :setting="setting"
          @show-setting="openShowModal"
        />
      </div>
    </div>
    <v-modal
      v-if="showModal"
      :setting="selectedSetting"
      :modal-mode="modalMode"
      :suppliers-infos="suppliersInfos"
      :loading="loading"
      @close-modal="closeModal"
    />

  </div>
</template>

<script>
// @ is an alias to /src
import SupplierDetailCard from '@/components/v2/SupplierDetailCard.vue'
import Tag from '@/components/Tag.vue'
import VButton from '@/components/Button.vue'
import VModal from '@/components/v2/Modal.vue'
import VConfirmModal from '@/components/ConfirmModal.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { groupBy } from '../../utils/utils'
import moment from 'moment'
import Loader from './Loader'

export default {
  name: 'SupplierDetail',
  components: {
    SupplierDetailCard,
    Tag,
    VModal,
    VConfirmModal,
    VButton,
    Loader
  },
  computed: {
    ...mapGetters('npasupplier', ['settings', 'suppliersInfos']),
    ...mapState('global', ['year']),
    getStartDate() {
      return moment(this.settings[0].startdate.value).format("DD-MM-YYYY")
    },
    getEndDate() {
      return moment(this.settings[0].enddate.value).format("DD-MM-YYYY")
    }
  },
  data() {
    return {
      search: [],
      showModal: false,
      showConfirmModal: false,
      selectedSetting: null,
      modalMode: '',
      supplierCode: this.$route?.query?.id,
      gdn: this.$route?.query?.gdn,
      rayon: this.$route?.query?.rayon,
      loading: false,
      startDate: '',
      endDate: ''
    }
  },
  methods: {
    ...mapActions('npasupplier', [
      'fetchAllSuppliersInfos',
      'fetchNPASuppliersSettings'
    ]),
    groupBy: groupBy,
    setSearch(value) {
      this.search.push(value)
    },
    openAddModal() {
      this.modalMode = 'add'
      this.showModal = true
      this.selectedSetting = null
    },
    openShowModal(currentSetting) {
      this.modalMode = 'show'
      this.selectedSetting = currentSetting
      this.showModal = true
    },
    openEditModal(currentSetting) {
      this.modalMode = 'edit'
      this.selectedSetting = currentSetting
      this.showModal = true
    },
    openConfirmModal(currentSetting) {
      this.showConfirmModal = true
      this.selectedSetting = currentSetting
    },
    closeModal() {
      this.showModal = false
      this.showConfirmModal = false
      this.selectedSetting = null
    },
    async getSupplierSettings() {
      this.loading = true;
      await this.fetchNPASuppliersSettings({supplierCode: this.supplierCode, gdn: this.gdn, rayon: this.rayon, year: this.year})
      this.loading = false
    },
    async saveSetting(currentSetting) {
      currentSetting.supplierCode = this.supplierCode
      this.loading = true
      await this.addSupplierSetting(currentSetting)
        .then(() => {
          this.$toast.success(this.$t('SUPPLIER_DETAIL.SETTINGS.ADD_OK'))
          this.closeModal()
        })
        .catch(() => {
          this.$toast.error(this.$t('SUPPLIER_DETAIL.SETTINGS.ADD_ERROR'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    async editSetting(currentSetting) {
      currentSetting.supplierCode = this.supplierCode
      this.loading = true
      await this.updateSupplierSetting(currentSetting)
        .then(() => {
          this.$toast.success(this.$t('SUPPLIER_DETAIL.SETTINGS.UPDATE_OK'))
          this.closeModal()
        })
        .catch(() => {
          this.$toast.error(this.$t('SUPPLIER_DETAIL.SETTINGS.UPDATE_ERROR'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteSetting() {
      this.deleteSupplierSetting({ extractionid: this.selectedSetting.extractionid })
        .then(() => {
          this.$toast.success(this.$t('SUPPLIER_DETAIL.SETTINGS.DELETE_OK'))
          this.closeModal()
        })
        .catch(() => {
          this.$toast.error(this.$t('SUPPLIER_DETAIL.SETTINGS.DELETE_ERROR'))
        })
    }
  },
  mounted() {
    this.getSupplierSettings()
  }
}
</script>

<style scoped lang="scss">
.body-container {
  display: flex;
  margin-top: 1em;
  width: 100%;
}
.supplier-detail {
  &-header {
    margin: 0.5em 2.4em;
  }
}

.body-menu {
  -webkit-box-flex: 1;
  flex: 1 1 100%;
  max-width: 20%;
  height: fit-content;
  padding: 3em 1em 10em 2em;
  box-sizing: border-box;
  border-right: 1px solid $lightgrey;

  .tags {
    margin-left: 2.5em;
  }
}

.body-content {
  margin: 1em;
}

.add-btn {
  position: fixed;
  bottom: 1em;
  right: 1em;
}

.body-cards {
  margin: 1em;
  display: flex;
  flex-wrap: wrap;
}

.card {
  flex: 1 0 26%; /* explanation below */
}

.body-title {
  height: 32px;
  width: 100%;
  border-bottom: 1px solid $lightgrey;
}
.text {
   font-size: 0.9em !important;
 }

.scrollbar
{
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
</style>
