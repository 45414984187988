<template>
  <div class="main-container">
    <Loader :is-loading="loading" />
    <div class="text-teal flex justify-space">
      <br />
    </div>
    <div class="search">
      <div class="input-container ml-1">
        <div class="mr-2">
          <span class="label-date-debut"> {{ $t('RVF.START_DATE') }} </span>
          <date-picker
            v-model:value="fromDate"
            type="date"
            format="DD/MM/YYYY"
            class="mx-inputs"
            value-type="YYYY-MM-DD"
            popup-class="mx-popup"
            :placeholder="$t('RVF.START_DATE')"
            langString="fr"
            @change="periodChange"
            width="100px"
          />
        </div>
        <div class="mr-2">
          <span class="label-date-fin"> {{ $t('RVF.END_DATE') }} </span>
          <date-picker
            v-model:value="toDate"
            type="date"
            format="DD/MM/YYYY"
            class="mx-inputs"
            value-type="YYYY-MM-DD"
            popup-class="mx-popup"
            :placeholder="$t('RVF.END_DATE')"
            langString="fr"
            :disabled="disabled"
            :disabled-date="enabledUntilLastDayOfTheYear"
            width="100px"
          />
        </div>
        <div class="mr-2">
          <input
            v-model="rayonSearch"
            type="text"
            list="rayon"
            class="search-rayon"
            :placeholder="$t('RVF.RayonPlaceholder')"
            @keydown.enter="searchRVF()"
          />
          <datalist id="rayon">
            <option v-for="(rayonObj, key) in rayonList" :key="key" :value="rayonObj?.rayon">
              {{ rayonObj?.rayonLabel }}
            </option>
          </datalist>
        </div>
        <div class="mr-2">
          <input
            v-model="cnufSearch"
            type="text"
            list="suppliers"
            class="search-suppliers"
            :placeholder="$t('RVF.CNUF')"
            @keydown.enter="searchRVF()"
          />
          <datalist id="suppliers">
            <option v-for="(cnufObj, key) in cnufList" :key="key" :value="cnufObj?.paymentSupplierCode">
              {{ cnufObj?.paymentSupplierLabel }}
            </option>
          </datalist>
        </div>
        <div class="mr-2">
          <input
            v-model="gdnSearch"
            type="text"
            class="search-paymentlabel"
            :placeholder="$t('RVF.GDNPlaceholder')"
            list="paymentlabel"
            @keydown.enter="searchRVF()"
          />
          <datalist id="paymentlabel">
            <option v-for="(gdnObj, key) in gdnList" :key="key" :value="gdnObj?.paymentNegotiationGroupCode">
              {{ gdnObj?.paymentNegotiationGroupLabel }}
            </option>
          </datalist>
        </div>
        <div class="mr-2">
          <select v-model="typeSearch" type="text" class="search-paymentlabel" :placeholder="$t('RVF.TypePlaceholder')">
            <option value="" selected>{{ $t('RVF.AllTypes') }}</option>
            <option value="V">{{ $t('RVF.Sells') }}</option>
            <option value="S">{{ $t('RVF.Stocks') }}</option>
            <option value="E">{{ $t('RVF.StocksE') }}</option>
          </select>
        </div>
        <div class="mr-2">
          <select v-model="frequencySearch" type="text" class="search-paymentlabel">
            <option value="" selected>{{ $t('RVF.AllFrequencies') }}</option>
            <option value="DAY">{{ $t('RVF.DAILY') }}</option>
            <option value="WEE">{{ $t('RVF.WEEKLY') }}</option>
            <option value="MON">{{ $t('RVF.MONTHLY') }}</option>
            <option value="QUA">{{ $t('RVF.QUARTERLY') }}</option>
          </select>
        </div>
      </div>
      <div>
        <VButton :icon="'search'" color="transparent-blue" class="button-search" @action="searchRVF()" />
      </div>
    </div>
    <div class="mt-2">
      <ag-grid-vue
        style="width: 100%; height: 100%"
        class="ag-theme-alpine"
        @grid-ready="onGridReady"
        :columnDefs="columnDefs"
        :rowData="formattedDatas"
        :suppressCellSelection="true"
        :suppressRowClickSelection="true"
        @cellClicked="onCellClicked"
      />
    </div>
    <div class="mt-3">
      <strong v-if="formattedDatas.length" class="">{{ formattedDatas.length }} fichier(s)</strong>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { mapState, mapGetters, mapActions } from 'vuex'
  import VSearch from '@/components/Search.vue'
  import VButton from '@/components/Button.vue'
  import IconCellRenderer from '@/components/v2/iconCellRenderer.vue'
  import moment from 'moment'
  import Loader from './Loader'
  import { AgGridVue } from 'ag-grid-vue3'
  import { columnRvfDefs } from '../../utils/conf'
  import { saveAs } from 'file-saver'
  import DatePicker from 'vue-datepicker-next'
  import 'vue-datepicker-next/index.css'
  import 'vue-datepicker-next/locale/fr'

  export default {
    name: 'Rvf',
    components: {
      VSearch,
      Loader,
      VButton,
      AgGridVue,
      iconCellRenderer: IconCellRenderer,
      DatePicker
    },
    data() {
      return {
        search: [],
        isActivated: false,
        loading: false,
        loadingAlerts: false,
        nextItem: 1,
        items: [],
        displayed: 10, // Number of cards to displayed in oneShot
        step: 0,
        page: 1,
        fromDate: '',
        toDate: '',
        allRvfData: [],
        cnufSearch: '',
        rayonSearch: '',
        gdnSearch: '',
        typeSearch: '',
        frequencySearch: '',
        gridApi: null,
        columnDefs: columnRvfDefs,
        disabled: false
      }
    },
    computed: {
      ...mapGetters('npasupplier', ['rvfSales', 'allRVFData', 'userInfosByEmail', 'suppliers', 'suppliersInfos']),
      ...mapGetters('global', ['userInfos', 'userRoles', 'year']),
      ...mapState('global', ['year']),
      ...mapState('searchFilters', ['cnuf', 'rayon', 'gdn']),
      formattedDatas() {
        return this.rvfSales
      },
      rayonList() {
        let data = []
        return this.allRVFData.map(rayonData => {
          if (data.includes(rayonData.rayon)) return
          else data.push(rayonData.rayon)
          return { rayon: rayonData.rayon, rayonLabel: rayonData.rayonLabel }
        })
      },
      cnufList() {
        let data = []
        if (this.userInfos.displayName != null) {
          if (this.userRoles.includes('manager')) {
            return this.allRVFData.map(cnuf => {
              if (data.includes(cnuf.paymentSupplierCode)) return
              else data.push(cnuf.paymentSupplierCode)
              return { paymentSupplierCode: cnuf.paymentSupplierCode, paymentSupplierLabel: cnuf.paymentSupplierLabel }
            })
          } else {
            return this.suppliers.map(cnuf => {
              return { paymentSupplierCode: cnuf.paymentSupplierCode, paymentSupplierLabel: cnuf.paymentSupplierLabel }
            })
          }
        } else {
          return this.suppliersInfos.map(cnuf => {
            return { paymentSupplierCode: cnuf.paymentSupplierCode, paymentSupplierLabel: cnuf.paymentSupplierLabel }
          })
        }
      },
      gdnList() {
        return this.allRVFData.map(gdn => {
          return { paymentNegotiationGroupCode: gdn.paymentNegotiationGroupCode, paymentNegotiationGroupLabel: gdn.paymentNegotiationGroupLabel }
        })
      },
      getUserInfos() {
        return { user: this.userInfos, roles: this.userRoles }
      },
      getYear() {
        return this.year
      }
    },
    watch: {
      year() {
        this.fromDate = this.year
          ? moment().set({ year: this.year }).startOf('year').format('YYYY-MM-DD')
          : moment().startOf('year').format('YYYY-MM-DD')
        this.toDate = this.getLastDateOfYear(this.year)

        this.loadRVFSales()
      },
      cnufSearch(newValue, old) {
        if (newValue !== old) {
          this.$store.commit('searchFilters/setCnuf', this.cnufSearch)
        }
      },
      rayonSearch(newValue, old) {
        if (newValue !== old) {
          this.$store.commit('searchFilters/setRayon', this.rayonSearch)
        }
      },
      gdnSearch(newValue, old) {
        if (newValue !== old) {
          this.$store.commit('searchFilters/setGDN', this.gdnSearch)
        }
      }
    },
    mounted() {
      this.fromDate = moment().set({ year: this.year }).startOf('year').format('YYYY-MM-DD')
      this.toDate = this.getLastDateOfYear(this.year)

      this.cnufSearch = this.cnuf
      this.rayonSearch = this.rayon
      this.gdnSearch = this.gdn

      this.loadRVFSales()
    },
    methods: {
      ...mapActions('npasupplier', ['fetchRVFSales', 'fetchRVFData', 'fetchSuppliers', 'fetchSuppliersInfos']),
      ...mapActions('supplier', ['downloadFileRVF']),
      async loadRVFSales(isSearch = false) {
        this.loading = true
        let suppliersCode
        if (this.userInfos.displayName != null) {
          if (!this.userRoles.includes('manager') && this.cnufSearch.toLowerCase() === '') {
            await this.fetchSuppliers({ purchaser: this.userInfos.uid, year: this.year })
            suppliersCode = this.suppliers.map(supplier => supplier.paymentSupplierCode).join()
          } else {
            suppliersCode = this.cnufSearch?.toLowerCase()
          }
        } else {
          await this.fetchSuppliersInfos({ search: '', rayon: '', uid: '', email: this.userInfos.email, year: this.year, gdn: '' })
          suppliersCode = this.suppliersInfos.map(supplier => supplier.paymentSupplierCode).join()
          suppliersCode = suppliersCode === '' ? 'NO DATA SUPPLIER' : suppliersCode
        }
        if (!isSearch) this.allRvfData = await this.fetchRVFData()
        await this.fetchRVFSales({
          fromDate: this.fromDate,
          toDate: this.toDate,
          cnuf: this.cnufSearch ? this.cnufSearch.toString() : suppliersCode,
          rayon: this.rayonSearch,
          gdnPayment: this.gdnSearch.toLowerCase(),
          type: this.typeSearch,
          year: this.year,
          email: this.userInfos.displayName ? '' : this.userInfos.email,
          purchaser: this.userRoles?.includes('manager') ? '' : this.userInfos.displayName ? this.userInfos.uid : '',
          frequency: this.frequencySearch
        })

        this.loading = false
      },
      searchRVF() {
        this.loadRVFSales(true)
      },
      getLastDateOfYear(year) {
        if (moment().format('YYYY-MM') === moment().set({ year: year }).format('YYYY-MM')) return moment().endOf('month').format('YYYY-MM-DD')
        else return moment().set({ year: this.year }).endOf('year').format('YYYY-MM-DD')
      },

      async onGridReady(params) {
        this.gridApi = params.api
      },

      onCellClicked(params) {
        if (params.colDef.field === 'download') {
          this.downloadFile('DATAOBS_V2/PRT/' + params.data.fileName, params.data.fileName)
        }
      },

      async downloadFile(file, nameFile) {
        let csvData = await this.downloadFileRVF({ nameFile: file })
        saveAs(csvData.file[0], nameFile)
      },

      enabledUntilLastDayOfTheYear(date) {
        return date < new Date(this.fromDate)
      },

      periodChange() {
        if (this.fromDate) {
          this.disabled = false
        } else {
          this.toDate = null
          this.disabled = true
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  @import '~@/styles/_colors';

  .main-container {
    padding-bottom: 20rem;
    height: 100%;
  }

  .search {
    border-bottom: 1px solid #009688;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .date-fin,
  .date-debut {
    font-size: 15px;
    width: 150px;
    border: none;
    padding-bottom: 5px;
    margin-right: 20px;
    border-bottom: 1px solid rgba(2, 2, 2, 0.1);

    &:focus {
      outline: none;
      border-bottom: 2px solid #009688 !important;
    }
  }

  .label-date-fin {
    font-size: 1em;
  }

  .label-date-debut {
    font-size: 1em;
  }

  .input-container {
    display: flex;
    width: 100%;

    div {
      width: 100%;
    }
  }

  .search-rayon,
  .search-paymentlabel,
  .search-suppliers {
    background-color: #ffffffe1;
    border: 0;
    color: #000000;
    height: 44px;
    outline: none;
    width: 100%;
    font-size: 100%;
    border-radius: 8px 8px 0px 0px;
    padding: 1em 0 0.3em 0.7em;
    margin-right: 2em;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(2, 2, 2, 0.1);

    &::placeholder {
      color: darkgray;
    }

    &:focus {
      border-bottom: 2px solid #009688 !important;
    }
  }
  /* Add a custom class to target cells */
  .unselectable .ag-cell {
    user-select: none; /* Disable text selection */
  }

  /* Remove focus border */
  .unselectable .ag-cell-focus {
    outline: none;
  }
</style>
