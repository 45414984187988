import { createRouter, createWebHistory } from 'vue-router'
import RVF from '../views/Rvf.vue'
import V2RVF from '../views/v2/Rvf.vue'
import SupplierSearch from '../views/SupplierSearch.vue'
import V2SupplierSearch from '../views/v2/SupplierSearch.vue'
import SupplierDetail from '../views/SupplierDetail.vue'
import V2SupplierDetail from '../views/v2/SupplierDetail.vue'
import V2Infos from '../views/v2/Infos.vue'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/supplierSearch',
    name: 'SupplierSearch',
    component: SupplierSearch
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/v2/infos',
    name: 'V2Infos',
    component: V2Infos
  },
  {
    path: '/v2/supplierSearch',
    name: 'v2',
    component: V2SupplierSearch
  },
  {
    path: '/v2/supplier-detail',
    name: 'vsupplierdetail',
    component: V2SupplierDetail
  },
  {
    path: '/v2/rvf',
    name: 'V2rvf',
    component: V2RVF
  },
  {
    path: '/rvf',
    name: 'rvf',
    component: RVF
  },
  {
    path: '/supplier-detail',
    name: 'SupplierDetail',
    component: SupplierDetail
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
