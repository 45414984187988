<template>
  <label class="wrapper flex items-center">
    {{label}}
    <input class="radio" type="radio" :checked="isChecked" disabled :value="value" @change="$emit('setValue', $event.target.value)" />
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    "label": { type: String, default: "", required:true },
    "modelValue": { default: "" },
    "value": { type: String, default: undefined }
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/_colors";
/* Customize the label (the wrapper) */
.wrapper {
  display: block;
  position: relative;
  padding: 0 0 0 35px;
  margin-bottom: 6px;
  margin-right: 1em;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
}
/* Hide the browser's default radio button */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  background-color: #eee;
  border: 1px solid #ccc; // if sele
}
/* On mouse-over, add a grey background color */
.wrapper:hover input ~ .checkmark {
  // border: 1px solid $maingreen; // if sele
  background-color: #ccc;
}
/* When the radio button is checked, add a blue background */
.wrapper input:checked ~ .checkmark {
  border: 1px solid $maingreen; // if sele
  background-color: white;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.wrapper input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.wrapper .checkmark:after {
  top: 2px;
  left: 2px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $maingreen;
}
</style>