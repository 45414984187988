<template>
  <div class="modal-container">
    <div class="overlay"></div>
    <div class="modal">
      <div class="modal-header flex justify-space align-center">
        <span class="text-white">{{ $t('MODAL.TITLE.' + this.modalMode) }} ( {{ `${setting.rayon} - ${setting.orderSupplierCode} - ${setting.paymentNegotiationGroupCode}` }} )</span>
        <v-svg class="icon" name="cross" :size="'16px'" @click="closeModal()" />
      </div>

      <div class="modal-body">
        <div class="search mt-3">
          <span class="label-date-debut">{{ $t('MODAL.CAMPAIGN_PERIOD') }} {{ $t('FROM') }}</span>
          <strong class="date-debut ml-1"> {{ form.startDate }}</strong> {{ $t('TO') }} <strong class="label-date-fin"> {{ form.endDate }} </strong>
        </div>

        <br/>
        <span class="text-bold">{{ $t('MODAL.SUPPLIER_PROFILE') }} : </span> <span> {{ setting.orderSupplierCode.toString() }} </span>

        <br/> <br/>
        <span class="text-bold">{{ $t('MODAL.SEND_MODE') }}</span>
        <div class="flex mt-1">
          <v-radio-button label="Portail" value="PRT" v-model="form.sendMode" />
          <v-radio-button label="EDI" value="EDI" v-model="form.sendMode" />
        </div>

        <div v-if="this.form.sendMode != 'EDI' && this.form.sendMode != 'PRT'">
          <br/>
          <span class="text-bold">{{ $t('MODAL.CONSOLIDATION') }}</span>
          <div class="flex mt-1">
            <v-radio-button label="Magasin" value="MAG" v-model="form.perimeter"/>
            <v-radio-button label="Société" value="ALL" v-model="form.perimeter"/>
          </div>
        </div>

        <br/>
        <span class="text-bold">{{ $t('MODAL.STATS') }}</span>
        <div class="flex mt-1">
          <v-checkbox
            v-for="(st, index) in allTypOfExtraction"
            :key="index"
            :label="st.label"
            :trueValue="st.value"
            v-model="setting.typofextraction"
          />
        </div>

        <br/>
        <span class="text-bold">{{ $t('MODAL.FREQUENCY') }}</span>
        <div class="flex mt-1">
          <v-radio-button label="Journalière" value="DAY" v-model="form.frequency" />
          <v-radio-button label="Hebdomadaire" value="WEE" v-model="form.frequency" />
          <v-radio-button label="Mensuelle" value="MON" v-model="form.frequency" />
          <v-radio-button label="Trimestrielle" value="QUA" v-model="form.frequency" />
        </div>

        <div >
          <br/>
          <span class="text-bold">{{ $t('MODAL.EXTRACTION_DAY') }}</span>

          <p v-if="form.frequency !=='MON' && form.frequency !=='QUA'">{{ $t(`MODAL.NPA_EXTRACTION_DAYS.${setting.dayofextraction.toString()}`) }} </p>
          <p v-if="form.frequency !=='DAY' && form.frequency !=='WEE'">{{ $t(`MODAL.NPA_EXTRACTION_DAYS.M`) }}</p>
        </div>

        <div v-if="setting.typofextraction === 'V' && this.form.sendMode != 'EDI'">
          <br/>
          <span class="text-bold">{{ $t('MODAL.AVERAGE_PRICE') }}</span>
          <div class="flex mt-1">
            <v-radio-button
              label="Oui"
              :value="true"
              v-model="setting.ispurchasepriceaveragecolumn"
            />
            <v-radio-button
              label="Non"
              :value="false"
              v-model="setting.ispurchasepriceaveragecolumn"
            />
          </div>


          <br/>
          <span class="text-bold">{{ $t('MODAL.CASHIER_SWITCH') }}</span>
          <div class="flex mt-1">
            <v-radio-button
              label="Oui"
              :value="true"
              v-model="setting.ischeckout"
            />
            <v-radio-button
              label="Non"
              :value="false"
              v-model="setting.ischeckout"
            />
          </div>

          <br/>
          <span class="text-bold">{{ $t('MODAL.NUMBER_SELL_PER_CASHIER_SWITCH') }}</span>
          <div class="flex mt-1">
            <v-radio-button
              label="Oui"
              :value="true"
              v-model="setting.ischeckoutquantity"
            />
            <v-radio-button
              label="Non"
              :value="false"
              v-model="setting.ischeckoutquantity"
            />
          </div>

          <br/>
          <span class="text-bold">{{ $t('MODAL.RANKING_PRODUCED_PER_SUPPLIER_FOR_EACH') }}</span>
          <div class="flex mt-1">
            <v-checkbox
              :label="$t('MODAL.RANKIG_CHOICE_CA')"
              :trueValue="true"
              v-model="setting.isrankingsalesproductbysupplier"
            />
            <v-checkbox
              :trueValue="true"
              :label="$t('MODAL.RANKIG_CHOICE_QTE')"
              v-model="setting.isrankingquantityproductbysupplier"
            />
          </div>

          <br/>
          <span class="text-bold">{{ $t('MODAL.RANKING_OF_PRODUCTS_TO_GLOBAL_SOCIETY') }}</span>
          <div class="flex mt-1">
            <v-checkbox
              :label="$t('MODAL.RANKIG_CHOICE_CA')"
              :trueValue="true"
              v-model="setting.isrankingsalesproductcompagny"
            />
            <v-checkbox
              :label="$t('MODAL.RANKIG_CHOICE_QTE')"
              :trueValue="true"
              v-model="setting.isrankingquantityproductcompagny"
            />
          </div>

          <br/>
          <span class="text-bold">{{ $t('MODAL.RANKING_OF_SITES_PER_PRODUCTS') }}</span>
          <div class="flex mt-1">
            <v-checkbox
              :label="$t('MODAL.RANKIG_CHOICE_CA')"
              :trueValue="true"
              v-model="setting.isrankingsalessitebyproduct"
            />
            <v-checkbox
              :label="$t('MODAL.RANKIG_CHOICE_QTE')"
              :trueValue="true"
              v-model="setting.isrankingquantitysitebyproduct"
            />
          </div>

          <br/>
          <span class="text-bold">{{ $t('MODAL.RANKING_OF_SITES_PER_PRODUCT_FORMAT') }}</span>
          <div class="flex mt-1">
            <v-checkbox
              :label="$t('MODAL.RANKIG_CHOICE_CA')"
              :trueValue="true"
              v-model="setting.isrankingsalessitebyformatbyproduct"
            />
            <v-checkbox
              :label="$t('MODAL.RANKIG_CHOICE_QTE')"
              :trueValue="true"
              v-model="setting.isrankingquantitysitebyformatbyproduct"
            />
          </div>
          <br/><br/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import VSelect from '@/components/Select'
import VRadioButton from '@/components/v2/RadioButton'
import VCheckbox from '@/components/v2/Checkbox'
import VSelectCheckbox from '@/components/SelectCheckbox'
import moment from 'moment'

export default {
  name: 'VModal',
  components: {
    VSelect,
    VRadioButton,
    VCheckbox,
    VSelectCheckbox
  },
  props: {
    setting: {
      type: Object,
      default: () => {}
    },
    sectors: {
      type: Array,
      default: () => []
    },
    markets: {
      type: Array,
      default: () => []
    },
    suppliersInfos: {
      type: Array,
      default: () => []
    },
    modalMode: {
      type: String,
      default: 'add'
    },
    loading: {
      type: Boolean,
      default: false
    },
    negos: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.filteredMarket = this.markets
    console.log("isrankingsalesproductbysupplier", this.setting)
  },
  computed: {
    ...mapState('user', ['foundUsers']),
    ...mapState('supplier', ['negoGroups, NPAsettings']),
    ...mapGetters('user', ['searchUsers']),
    formattedSectors() {
      return this.sectors.map(s => {
        return {
          sid: s.sid,
          id: s.id,
          label: s.label
        }
      })
    },
    formattedMarkets() {
      return this.markets.map(m => {
        return {
          sid: m.sid,
          id: m.id,
          label: m.label
        }
      })
    },
    formattedNego() {
      return this.negoGroups.map(m => {
        return {
          id: m.sid,
          label: m.label
        }
      })
    },
    formattedSuppliersInfos() {
      return this.suppliersInfos.map(s => {
        return {
          id: s,
          label: s.managerProfile + ' - ' + s.ifls + ' - ' + s.name
        }
      })
    },
    isFormFilled() {
      return Object.values(this.form).every(x => x === null || x === '')
    }
  },
  data() {
    return {
      form: {
        startDate: this.setting ? moment(this.setting.startdate.value).format('DD/MM/YYYY') : moment().format('YYYY-MM-DD'),
        endDate: this.setting ? moment(this.setting.enddate.value).format('DD/MM/YYYY') : moment().format('YYYY-MM-DD'),
        supplierCode: this.setting ? this.setting.suppliercode : '',
        typOfExtraction: this.setting ? this.setting.typofextraction.split(',') : [],
        sendMode: this.setting ? this.setting.sendmode : '',
        statusOfThePurchasePriceAverageColumn: this.setting ? this.setting.statusofthepurchasepriceaveragecolumn : 'N',
        frequency: this.setting ? this.setting.frequency : '',
        perimeter: this.setting ? this.setting.perimeter : '',
        sectorId: this.setting ? this.setting.sectorid : '',
        marketsList: this.setting ? this.setting.market : [],
        dayOfExtraction: this.setting ? this.setting.dayofextraction : '1',
        negoGroupe: []
      },
      extractionsOptions: [
        { label: this.$t('MODAL.MONDAY'), value: '1' },
        { label: this.$t('MODAL.TUESDAY'), value: '2' },
        { label: this.$t('MODAL.WEDNESDAY'), value: '3' },
        { label: this.$t('MODAL.THURSDAY'), value: '4' },
        { label: this.$t('MODAL.FRIDAY'), value: '5' },
        { label: this.$t('MODAL.SATURDAY'), value: '6' },
        { label: this.$t('MODAL.SUNDAY'), value: '7' }
      ],
      allTypOfExtraction: [
        {
          label: this.$t('SUPPLIER_SEARCH.STAT_CHOICES.E'),
          value: 'E'
        },
        {
          label: this.$t('SUPPLIER_SEARCH.STAT_CHOICES.M'),
          value: 'S'
        },
        {
          label: this.$t('SUPPLIER_SEARCH.STAT_CHOICES.V'),
          value: 'V'
        }
      ],
      filteredMarket: [],
      gettingNego: false
    }
  },
  methods: {
    ...mapActions('supplier', ['fetchNegoGroup', 'fetchNPASuppliersSettings']),
    closeModal() {
      this.$emit('close-modal')
    },
    setNegoList(negoList) {
      if (Array.isArray(negoList)) {
        this.form.negoGroupe = negoList
      }
    },
    emitSetting() {
      this.$emit(this.modalMode, {
        ...(this.modalMode === 'edit' && { extractionid: this.setting.extractionid }),
        ...(this.modalMode === 'edit' && { createdDate: this.setting.createddate }),
        ...(this.modalMode === 'edit' && { createdBy: this.setting.createdby }),
        sendMode: this.form.sendMode,
        sectorId: this.form.sectorId,
        supplierCode: this.form.supplierCode,
        frequency: this.form.frequency,
        dayOfExtraction: this.form.frequency === 'WEE' ? this.form.dayOfExtraction : null,
        perimeter: this.form.sendMode === 'PRT' ? this.form.perimeter : null,
        typOfExtraction: this.form.typOfExtraction,
        marketsList: this.form.marketsList.join(','),
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        extractionStatus: this.form.extractionStatus ? this.form.extractionStatus : null, //TODO
        statusOfThePurchasePriceAverageColumn: this.form.statusOfThePurchasePriceAverageColumn,
        negoGroupSids: this.form.negoGroupe.join(',')
      })
    },
    formValuesSetted() {
      if (
        !this.form.sendMode ||
        !this.form.sectorId ||
        !this.form.frequency ||
        (this.form.frequency === 'WEE' && !this.form.dayOfExtraction) ||
        (this.form.sendMode === 'PRT' && !this.form.perimeter) ||
        this.form.typOfExtraction.length === 0 ||
        this.form.marketsList.length === 0 ||
        !this.form.statusOfThePurchasePriceAverageColumn
      ) {
        return false
      }

      return true
    }
  }
}
</script>
<style scoped lang="scss">
@import '~@/styles/_colors';
.spinner-container {
  display: inline-block;
  background-color: transparent !important;
  vertical-align: middle;
}
.spinner-container,
.button {
  position: relative;
  background-color: $maingreen;
  color: white;
  width: 80px;
  height: 40px;
  border: 0;
  margin: 0.5em;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}

.button:active {
  background: #007a63;
}

.button__text {
  // font: bold 20px "Quicksand", san-serif;
  color: #ffffff;
  transition: all 0.2s;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.spinner-container::after {
  border-top-color: $maingreen !important;
  box-shadow: 0 0 5px 0 rgb(141, 140, 140);
}

.spinner-container::after,
.button--loading::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 0.8s ease-in infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.autocomplete {
  width: 100%;
}
.footer {
  margin-top: 1em !important;
}

.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  background: white;
  color: #333;
  position: fixed;
  top: 6%;
  width: 78rem;
  max-height: 81%;

  &-body {
    padding: 1rem 2rem;
    overflow: auto;
    height: 500px;
    overflow-x: hidden;
  }

  &-header {
    background-color: $maingreen;
    padding: 0 1em;
    height: 4em;
  }
}

.icon {
  color: white;

  &:hover {
    cursor: pointer;
  }
}

.button[disabled] {
  &,
  &:active {
    background-color: #ccc;
  }
}
</style>
