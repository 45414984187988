  <template>
    <div class="autocomplete">
      <div class="autocomplete-search" :class="focused ? 'autocomplete-search-focus' : ''">
        <v-label class="autocomplete-label" v-for="(label, index) in labels" :key="index" :label="label.label" @action="removeLabel(index)" v-if="!isEnter"/>
        <v-label class="autocomplete-label"   :label="this.searchAll" @action="removeAllLabel()" v-if="isEnter && this.searchAll.length>0"/>
        <input
          id="search-input"
          type="text"
          autocomplete="off"
          :placeholder="placeholder"
          v-model="search"
          @input="filterResults"
          @click="reOpenResults"
          @keydown.down="onArrowDown"
          @keydown.up="onArrowUp"
          @keydown.enter="onEnter"
          @focus.native="focused = true"
        />
      </div>
      <div
        v-show="isOpen && results.length"
        class="autocomplete-results"
        id="results"
      >

        <div
          v-for="(result, i) in results"
          :key="i"
          @click="setResult(result); repositionResults()"
          class="autocomplete-result flex justify-space"
          :class="{ 'is-active': i === arrowCounter - 1 }"
        >
          {{ result.label }}
        </div>
      </div>
  </div>
</template>

<script>
import VLabel from '@/components/Label'

  export default {
    name: 'autocomplete',
    components: { VLabel },
    props: {
      /**
       * Component width
      */
      width: {
        type: String,
        default: '1000'
      },
      /**
       * Input placeholder
      */
      placeholder: {
        type: String,
        default: function () {
          return ''
        }
      },
      /**
       * Search items
      */
      items: {
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
        isOpen: false,
        results: [],
        labels: [],
        search: '',
        arrowCounter: 0,
        focused: false,
        isEnter: false,
        searchAll: '',
        searchAll: '',
      };
    },
    methods: {
      filterResults() {
        if(this.search.length>2)
         this.results = this.items.filter(item => {
          return item.label.toLowerCase().indexOf(this.search.toLowerCase()) > -1
         })
      },
      setResult(result, index) {
        if (!this.labels.find(s => s.el.id == result.el.id)) {
          this.labels.push(result)
        }
        this.search = ''
        this.isOpen = false
        this.results= []
        this.$emit('set-search', this.labels, index)
      },
      removeLabel(index) {
        this.searchAll = ''
        this.labels.splice(index, 1)
        this.repositionResults()
        this.$emit('set-search', this.labels)
      },
      removeAllLabel() {
        this.searchAll = ''
        this.labels = []
        this.isEnter = false
        this.$emit('set-search', this.labels)
      },
      onArrowDown(evt) {
        if (this.arrowCounter < this.results.length) {
          this.arrowCounter = this.arrowCounter + 1
        }
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter -1
        }
      },
      onEnter() {
        this.labels = []
        this.isEnter = true
        this.results.forEach(s=> this.labels.push(s))
        this.isOpen = false
        this.arrowCounter = 0
        this.searchAll = this.search
         this.search = ''
        this.results= []
        this.repositionResults()
        this.$emit('set-search', this.labels)
      },
      reOpenResults() {
        if (this.results.length) {
          this.isOpen = true
        }
      },
      handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
          this.isOpen = false
          this.focused = false
          this.arrowCounter = 0
        }
      },
      repositionResults() {
        setTimeout(() => {
          var ele = document.getElementById('search-input');
          var left = 0;

          while(ele.tagName != "BODY") {
              left += ele.offsetLeft;
              ele = ele.offsetParent;
          }

          document.getElementById("results").style.marginLeft = left + 'px'
        }, 100)
      }
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside)
    },
    watch: {
      search(val) {
        this.isOpen = val.length ? true : false
      }
    }
  };
</script>

<style scoped lang="scss">
@import "~@/styles/_colors";

  .autocomplete {
    width: 100%;
    &-label{
      display: flex;
      align-items: center;
      color: $darkgrey;
      font-size: 14px;
    }
  }

  .autocomplete-search {
    border-radius: 8px 8px 0px 0px;
    padding: 1em 0 0.3em 0;
    margin: 0 1em;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid $lightgrey;

    &-focus {
        border-bottom: 2px solid rgb(0, 150, 136) !important;
    }
  }

  .autocomplete-search {
    input {
      background-color: $background;
      border: 0;
      color: $black;
      height: 44px;
      outline: none;
      width: 300px;
      font-size: 100%;

      &::placeholder {
        color: $mediumgrey !important;
      }
    }
  }

  .autocomplete-search:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0px;
    bottom: 0;
    width: 20px;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    position: absolute;
    z-index: 1;
    background-color: white;
    overflow: auto;


    left: 19px;
    min-width: 400px;
    max-width: 1253px;
    top: 128px;
    bottom: auto;
    max-height: 250px;
    height: 250px;

    &-categories {
      text-align: left;
      padding: 1em 1em 0.5em 1em;
      font-weight: bold;
    }

  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    cursor: pointer;

    font-size: 14px;
    overflow: hidden;
    padding: 0 15px;
    line-height: 48px;
    height: 48px;
    -webkit-transition: background .15s linear;
    transition: background .15s linear;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: $lightgrey;
  }

  .right-inner-addon {
    position: relative;
  }
  .right-inner-addon input {
    padding-right: 30px;
  }
</style>
