<template>
  <div class="custom-select" @blur="open = false" :style="{ width }">
    <div class="selected" :class="{ open }" @click="open = !open">
      {{ selected && selected.label }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        class="item"
        v-for="(option, i) of options"
        :key="i"
        @click="onClick(option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VSelect',
  props: {
    options: {
      type: Array,
      required: true
    },
    defaultStateId: {
      type: String,
      required: false,
      default: null
    },
    defaultOptionId: {
      type: String,
      required: false,
      default: null
    },
    width: {
      type: String,
      default: null
    },
    selectName: {
      type: String,
      default: null
    },
  }, 
  data() {
    return {
      // selected: null,
      selected: this.defaultOptionId ? this.getSelectedOption()  : '',
      open: false
    };
  },
  methods: {
    onClick(option) { 
      this.selected = option
      this.open = false
      this.$emit('input', option)
    },
    getSelectedOption() { 
      switch (this.selectName) {
        case 'supplier':
        return this.options.find(o => o.id.ifls.toString() === this.defaultOptionId);
          break;
        case 'sector':
          return this.options.find(o => o.id.toString() === this.defaultOptionId);
            break;
        case 'market':
          return this.options.find(o => o.id === this.defaultOptionId);
            break;
        case 'extraction-day':
          return this.options.find(o => o.value === this.defaultOptionId);
            break;
      
        default: ''
          break;
      }
      
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/_colors";

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;
}

.selected {
  background-color: white;
  border-radius: 6px;
  // color: $darkblue;
  padding-left: 8px;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid $lightgrey;
}

.selected.open{
  border-bottom: 1px solid $lightgrey;
  border-radius: 6px 6px 0px 0px;
}

.selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 10px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-color: $blue transparent transparent transparent;
}

.items {
  color: white;
  z-index: 1;
  border-radius: 0px 0px 6px 6px;
  overflow: scroll;
  height: 200px;
  border-bottom: 1px solid $lightgrey;
  position: absolute;
  background-color: white;
  left: 0;
  right: 0;
}

.item{
  color: black;
  padding-left: 8px;
  cursor: pointer;
  user-select: none;
}

.item:hover{
  color: white;
  background-color: $maingreen;
  color: #E0E3F8;
}

.selectHide {
  display: none;
}
</style>
