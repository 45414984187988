<template>
  <div class="label opacity" @click="$emit('action')">
    <v-svg class="icon" name="contact" size="20px"></v-svg>
    <label class="text-bold">{{ label }}</label>
    <v-svg class="icon" size="16px" name="cross" ></v-svg>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg'

export default {
  name: 'VLabel',
  components: {
    VSvg
  },
  props: {
    /**
     * label's text
    */
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/styles/_colors";

.label {
  border-radius: 30px;
  padding: 0.6em 1em 0.6em 0.5em;
  margin: 0.3em;
  height: fit-content;
  width: fit-content;
  text-align: center;
  background-color: $lightgrey;
  color: black;

  .icon:first-child {
    margin-right: 0.5em;
  }

  .icon {
    margin-left: 0.5em;
    font-size: 6px;
    color: black;
  }
  
  .label {
    padding: 0 0.5em;
  }
}
</style>
