import * as ExcelJS from "exceljs"
const Papa = require('papaparse')
import { Workbook, Worksheet } from 'exceljs'
import { saveAs } from 'file-saver'

export const exportXls = (excelData) => {

  //Title, Header & Data
  const title = excelData.title
  const header = excelData.headers
  const data = excelData.data

  //Create a workbook with a worksheet

  let workbook = new Workbook()
  let worksheet = workbook.addWorksheet('SuppliersInfos')

  //Adding Header Row
  let headerRow = worksheet.addRow(header)
  headerRow.eachCell((cell, number) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '4167B8' },
      bgColor: { argb: '' },
    }
    cell.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 12
    }
  })


  // Columns width
  worksheet.getColumn(1).width = 20
  for (let i = 2; i <= header.length; i++) {
    worksheet.getColumn(i).width = (i%2!=0) ? 60 : 20
  }

  // Adding Data
  data.forEach(d => {
      let row = worksheet.addRow(d)
/*      let sales = row.getCell(2)
      let color = 'FF99FF99';
      sales.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }*/
    }
  )

  workbook.xlsx.writeBuffer().then(file => {
    try {
    let blob = new Blob([file], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
/*
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = `${title}.xlsx`;
      a.click();
*/
    saveAs(blob, `${title}.xlsx`)
    } catch (e) {

    }
  })

}

  /*var encodedUri = encodeURI('data:text/xls,' + Papa.unparse(data))
  var link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', filename + '.xls')
  document.body.appendChild(link) // Required for FF

  link.click() // This will download the data file named "my_data.xls"*/

export const exportXls2= (data, filename) => {
  var encodedUri = encodeURI('data:text/xls,' + Papa.unparse(data))
  var link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', filename + '.xls')
  document.body.appendChild(link) // Required for FF

  link.click() // This will download the data file named "my_data.xls".
}

export const importXls = (xlsText) => {
  return Papa.parse(xlsText, { skipEmptyLines: true, header: true, transform: (v) => (v ? v : null) }).data
}

export const exportXlsSuppliersInfos= (excelData) => {

  //Title, Header & Data
  const title = excelData.title
  const header = excelData.headers
  const data = excelData.data

  //Create a workbook with a worksheet

  let workbook = new Workbook()
  let worksheet = workbook.addWorksheet('SuppliersInfos')

  //Adding Header Row
  let headerRow = worksheet.addRow(header)
  headerRow.eachCell((cell, number) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '4167B8' },
      bgColor: { argb: '' },
    }
    cell.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 12
    }
  })

  // Columns width
  for (let i = 1; i <= header.length; i++) {
    worksheet.getColumn(i).width = 60
  }

  // Adding Data
  data.forEach(d => {
      let row = worksheet.addRow(d)
      /*      let sales = row.getCell(2)
            let color = 'FF99FF99';
            sales.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: color }
            }*/
    }
  )

  workbook.xlsx.writeBuffer().then(file => {
    try {
      let blob = new Blob([file], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      /*
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = `${title}.xlsx`;
            a.click();
      */
      saveAs(blob, `${title}.xlsx`)
    } catch (e) {

    }
  })

}


