<template>
  <div class="supplier-detail">
    <div class="supplier-detail-header" v-if="settings?.length">
      <div class="body-title flex justify-space align-center">
        <span class="text-big">{{ getEndDate }} ({{ settings.length }} {{ $t('SUPPLIER_DETAIL.SETTING', settings.length) }})</span>
      </div>
    </div>
    <div class="body-content" v-if="settings?.length">
      <div class="body-cards flex">
        <supplier-detail-card
          class="card"
          v-for="(setting, index) in settings"
          :key="index"
          :setting="setting"
          @edit-setting="openEditModal"
          @delete-setting="openConfirmModal(setting)"
        />
      </div>
    </div>
    <v-modal
      v-if="showModal"
      :setting="selectedSetting"
      :sectors="sectors"
      :markets="markets"
      :modal-mode="modalMode"
      :suppliers-infos="suppliersInfos"
      :loading="loading"
      @close-modal="closeModal"
      @add="saveSetting"
      @edit="editSetting"
    />

    <v-confirm-modal
      :show-modal="showConfirmModal"
      @confirm="deleteSetting"
      @close-modal="closeModal"
    />

    <v-button class="add-btn" :icon="'add4'"  @click="openAddModal"/>
  </div>
</template>

<script>
// @ is an alias to /src
import SupplierDetailCard from '@/components/SupplierDetailCard.vue'
import Tag from '@/components/Tag.vue'
import VButton from '@/components/Button.vue'
import VModal from '@/components/Modal.vue'
import VConfirmModal from '@/components/ConfirmModal.vue'
import { mapActions, mapGetters } from 'vuex'
import { groupBy } from '../utils/utils'

export default {
  name: 'SupplierDetail',
  components: {
    SupplierDetailCard,
    Tag,
    VModal,
    VConfirmModal,
    VButton
  },
  computed: {
    ...mapGetters('supplier', ['settings', 'suppliersInfos']),
    ...mapGetters('nomenclature', ['markets', 'sectors']),
    getEndDate() {
      let lastIndex = this.settings?.length - 1
      let endDate = this.settings[lastIndex]?.enddate
      return  endDate.substring(0, 7)
    }
  },
  data() {
    return {
      search: [],
      showModal: false,
      showConfirmModal: false,
      selectedSetting: null,
      modalMode: '',
      supplierCode: this.$route.query.id,
      loading: false,
      endDate:''
    }
  },
  methods: {
    ...mapActions('supplier', ['fetchSuppliersSettings', 'fetchNPASuppliersSettings', 'addSupplierSetting', 'deleteSupplierSetting', 'updateSupplierSetting', 'fetchAllSuppliersInfos']),
    ...mapActions('nomenclature', ['fetchMarkets', 'fetchSectors']),
    groupBy: groupBy,
    setSearch(value) {
      this.search.push(value)
    },
    openAddModal() {
      this.modalMode = 'add'
      this.showModal = true
      this.selectedSetting = null
    },
    openEditModal(currentSetting) {
      this.modalMode = 'edit'
      this.selectedSetting = currentSetting
      this.showModal = true
    },
    openConfirmModal(currentSetting) {
      this.showConfirmModal = true
      this.selectedSetting = currentSetting
    },
    closeModal() {
      this.showModal = false
      this.showConfirmModal = false
      this.selectedSetting = null
    },
    async saveSetting(currentSetting) {

      currentSetting.supplierCode = this.supplierCode
      this.loading = true
      await this.addSupplierSetting(currentSetting)
      .then(() => {
        this.$toast.success(this.$t('SUPPLIER_DETAIL.SETTINGS.ADD_OK'))
        this.closeModal()
      })
      .catch(() => {
        this.$toast.error(this.$t('SUPPLIER_DETAIL.SETTINGS.ADD_ERROR'))
      })
      .finally(() => {
        this.loading = false
      })
    },
    async editSetting(currentSetting) {
      currentSetting.supplierCode = this.supplierCode
      this.loading = true
      await this.updateSupplierSetting(currentSetting).then(() => {
        this.$toast.success(this.$t('SUPPLIER_DETAIL.SETTINGS.UPDATE_OK'))
        this.closeModal()
      })
      .catch(() => {
        this.$toast.error(this.$t('SUPPLIER_DETAIL.SETTINGS.UPDATE_ERROR'))
      })
      .finally(() => {
        this.loading = false
      })
    },
    deleteSetting() {
      this.deleteSupplierSetting({ extractionid: this.selectedSetting.extractionid })
      .then(() => {
        this.$toast.success(this.$t('SUPPLIER_DETAIL.SETTINGS.DELETE_OK'))
        this.closeModal()
      })
      .catch(() => {
        this.$toast.error(this.$t('SUPPLIER_DETAIL.SETTINGS.DELETE_ERROR'))
      })
    }
  },
  mounted() {
    this.fetchMarkets()
    this.fetchSectors()
    this.fetchNPASuppliersSettings(this.supplierCode)
    this.fetchSuppliersSettings(this.supplierCode)
    this.fetchAllSuppliersInfos()
  }
}
</script>

<style scoped lang="scss">
.body-container {
  display: flex;
  margin-top: 1em;
  width: 100%;
}
.supplier-detail {
  &-header {
    margin: 0.5em 0 0.5em 2.4em;
  }

}

.body-menu {
  -webkit-box-flex: 1;
  flex: 1 1 100%;
  max-width: 20%;
  height: fit-content;
  padding: 3em 1em 10em 2em;
  box-sizing: border-box;
  border-right: 1px solid $lightgrey;

  .tags {
    margin-left: 2.5em;
  }
}

.body-content {
  margin: 1em;
}

.add-btn {
  position: fixed;
  bottom: 1em;
  right: 1em;
}

.body-cards {
  margin: 1em;
  display: flex;
  flex-wrap: wrap;
}

.card {
  flex: 1 0 26%; /* explanation below */
}

.body-title {
  height: 45px;
  width: 100%;
  border-bottom: 1px solid $lightgrey;
}
</style>
