const state = {
  cnuf: '',
  rayon: '',
  gdn: ''
}

const mutations = {
  setCnuf(state, cnuf) {
    state.cnuf = cnuf
  },
  setRayon(state, rayon) {
    state.rayon = rayon
  },
  setGDN(state, gdn) {
    state.gdn = gdn
  }
}

const actions = {}

const getters = {
  getRayon(state) {
    return state.rayon
  },
  getCnuf(state) {
    return state.cnuf
  },
  getGDN(state) {
    return state.gdn
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
