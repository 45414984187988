import Vuex from 'vuex'
import npasupplier from './modules/v2/supplier.store'
import supplier from './modules/supplier.store'
import user from './modules/user.store'
import nomenclature from './modules/nomenclature.store'
import global from './modules/global.store'
import searchFilters from './modules/v2/searchFilters.store'

const stores = {
  npasupplier,
  supplier,
  user,
  nomenclature,
  global,
  searchFilters
}

const state = {}
const getters = {}
const mutations = {}
const actions = {}

const modules = Object.entries(stores).reduce((modules, [name, store]) => {
  modules[name] = {
    namespaced: true,
    ...store
  }
  return modules
}, {})

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules
})
