<template>
  <div class="modal-container">
    <div class="overlay"></div>
    <div class="modal">
      <div class="modal-header flex justify-space align-center">
        <span class="text-white">{{ $t('MODAL.TITLE.'+this.modalMode) }}</span>
        <v-svg class="icon" name="cross" :size="'16px'" @click="closeModal()" />
      </div>

      <div class="modal-body">
        <div class="search">
          <span class="label-date-debut">Du</span> <input type="date" class="date-debut" v-model="form.startDate" />
          <span class="label-date-fin"> au </span> <input type="date" class="date-fin" v-model="form.endDate" />
        </div>


        <br />
        <span class="text-bold">{{ $t('MODAL.SUPPLIER_PROFILE') }}</span>
        <v-select
          class="mt-1"
          :options="formattedSuppliersInfos"
          :default-state="''"
          selectName="supplier"
          @input="$emit('update-per-page', $event)"
          :defaultOptionId="setting ? setting.suppliercode.toString() : this.$route.query.id"
        />

        <br />
        <span class="text-bold">{{ $t('MODAL.SEND_MODE') }}</span>
        <div class="flex mt-1">
          <v-radio-button label="Portail" value="PRT" v-model="form.sendMode" @set-value="updateSendMode" />
          <v-radio-button label="EDI" value="EDI" v-model="form.sendMode" @set-value="updateSendMode" />
        </div>

        <div v-if="this.form.sendMode != 'EDI' ">
          <br />
          <span class="text-bold">{{ $t('MODAL.CONSOLIDATION') }}</span>
            <div class="flex mt-1">
              <v-radio-button label="Magasin" value="MAG" v-model="form.perimeter" @set-value="updatePerimeter" />
              <v-radio-button label="Société" value="ALL" v-model="form.perimeter" @set-value="updatePerimeter" />
            </div>
        </div>

        <br />
        <span class="text-bold">{{ $t('MODAL.STATS') }}</span>
        <div class="flex mt-1">
          <v-checkbox v-for="(st, index) in allTypOfExtraction"
            :key="index"
            :label="st.label"
            :value="st.value"
            v-model="form.typOfExtraction"
            @set-value="updateTypOfExtraction"
          />
        </div>

        <br />
        <span class="text-bold">{{ $t('MODAL.FREQUENCY') }}</span>
        <div class="flex mt-1">
          <v-radio-button label="Journalière" value="DAY" v-model="form.frequency" @set-value="updateFrequency" />
          <v-radio-button label="Hebdomadaire" value="WEE" v-model="form.frequency" @set-value="updateFrequency" />
          <v-radio-button label="Mensuelle" value="MON" v-model="form.frequency" @set-value="updateFrequency" />
          <v-radio-button label="Trimestrielle" value="QUA" v-model="form.frequency" @set-value="updateFrequency" />
        </div>

        <div v-if="form.frequency === 'WEE'">
          <br />
          <span class="text-bold">{{ $t('MODAL.EXTRACTION_DAY') }}</span>
          <div class="flex mt-1">
            <v-select :options="extractionsOptions" selectName="extraction-day" :defaultOptionId="setting && setting.dayofextraction" v-model="form.dayOfExtraction" @input="updateExtractionDay" />
          </div>
          <p>{{ $t(`MODAL.EXTRACTION_DAYS.${form.dayOfExtraction}`) }}</p>
        </div>

        <br />
        <span class="text-bold">{{ $t('MODAL.AVERAGE_PRICE') }}</span>
        <div class="flex mt-1">
          <v-radio-button label="Oui" value="O" v-model="form.statusOfThePurchasePriceAverageColumn" @set-value="updateStatusOfThePurchasePriceAverageColumn" />
          <v-radio-button label="Non" value="N" v-model="form.statusOfThePurchasePriceAverageColumn" @set-value="updateStatusOfThePurchasePriceAverageColumn" />
        </div>

        <br />
        <span class="text-bold mt-2">{{ $t('MODAL.SECTOR') }}</span>
        <v-select class="mt-1" selectName="sector" :options="formattedSectors" v-model="form.sectorId" @input="updateSector" :defaultOptionId="setting && setting.sectorid.toString() " />

        <br/><br/>
        <div v-if="form.sectorId">
          <span class="text-bold mt-3">{{ $t('MARKETS') }}</span>
          <v-select-checkbox
            class="mt-1"
            :options="setting ? formattedMarkets : filteredMarket"
            :default-state-id="form.marketsList"
            @input="setMarketsList"
          />
        </div>

        <br/><br/>
        <span class="text-bold mt-3">{{ $t('MODAL.GROUPE_NEGO') }}</span>
        <div v-if="gettingNego" class="spinner-container"></div>
        <div v-if="negoGroups?.length">
          <v-select-checkbox
            class="mt-1"
            selectName="nego"
            :options="formattedNego"
            :default-state-id="form.negoGroupe"
            @input="setNegoList"
          />
        </div>
        <div v-else>
          <br/>
          Aucun groupe de négo disponible !
        </div>
      </div>

      <div class="footer flex justify-end mt-3 mt-3">
        <button :disabled="loading || !formValuesSetted()" :class="`button ${loading ? 'button--loading' : ''}`" @click="emitSetting" ><span class="button__text">Ok</span></button>
      </div>

    </div>

  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import VSelect from '@/components/Select'
import VRadioButton from '@/components/RadioButton'
import VCheckbox from '@/components/Checkbox'
import VSelectCheckbox from '@/components/SelectCheckbox'
import moment from 'moment'

export default {
  name: 'VModal',
  components: {
    VSelect,
    VRadioButton,
    VCheckbox,
    VSelectCheckbox
  },
  props: {
    setting: {
      type: Object,
      default: () => { }
    },
    sectors: {
      type: Array,
      default: () => []
    },
    markets: {
      type: Array,
      default: () => []
    },
    suppliersInfos: {
      type: Array,
      default: () => []
    },
    modalMode: {
      type: String,
      default: 'add'
    },
    loading: {
      type: Boolean,
      default: false
    },
    negos: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.filteredMarket = this.markets;
    this.setting && this.fetchNegoGroup({supplierCode: this.$route.query.id, sector: this.setting.sectorid, markets: this.setting.marketslist })
  },
  computed: {
    ...mapState('user', ['foundUsers']),
    ...mapState('supplier', ['negoGroups']),
    ...mapGetters('user', ['searchUsers']),
    formattedSectors() {
      return this.sectors.map(s => {
        return {
          sid: s.sid,
          id: s.id,
          label: s.label
        }
      })
    },
    formattedMarkets() {
      return this.markets.map(m => {
        return {
          sid: m.sid,
          id: m.id,
          label: m.label
        }
      })
    },
  formattedNego() {
    return this.negoGroups.map(m => {
      return {
        id: m.sid,
        label: m.label
      }
    })
  },
    formattedSuppliersInfos() {
      return this.suppliersInfos.map(s => {
        return {
          id: s,
          label: s.managerProfile + ' - ' + s.ifls + ' - ' + s.name
        }
      })
    },
    isFormFilled() {
      return Object.values(this.form).every(x => x === null || x === '');
    }
  },
  data() {
    return {
      form: {
        startDate:   this.setting ? new Date(this.setting.startdate).toISOString().substring(0, 10) : moment().format("YYYY-MM-DD"),
        endDate: this.setting ? new Date(this.setting.enddate).toISOString().substring(0, 10) :  moment().add(1, "M").format("YYYY-MM-DD"),
        supplierCode: this.setting ? this.setting.suppliercode : '',
        typOfExtraction: this.setting ? this.setting.typofextraction?.split(',') : [],
        sendMode: this.setting ? this.setting.sendmode : '',
        statusOfThePurchasePriceAverageColumn: this.setting ? this.setting.statusofthepurchasepriceaveragecolumn : 'N',
        frequency: this.setting ? this.setting.frequency : '',
        perimeter: this.setting ? this.setting.perimeter : '',
        sectorId: this.setting ? this.setting.sectorid : '',
        marketsList: this.setting ? this.setting.marketslist?.split(',') : [],
        dayOfExtraction: this.setting ? this.setting.dayofextraction : '1',
        negoGroupe: this.setting?.negogroupsids?.split(',') ?? [],
      },
      extractionsOptions: [
        { label: this.$t('MODAL.MONDAY'), value: '1' },
        { label: this.$t('MODAL.TUESDAY'), value: '2' },
        { label: this.$t('MODAL.WEDNESDAY'), value: '3' },
        { label: this.$t('MODAL.THURSDAY'), value: '4' },
        { label: this.$t('MODAL.FRIDAY'), value: '5' },
        { label: this.$t('MODAL.SATURDAY'), value: '6' },
        { label: this.$t('MODAL.SUNDAY'), value: '7' },
      ],
      allTypOfExtraction: [
        {
          label: this.$t('SUPPLIER_SEARCH.STAT_CHOICES.E'),
          value: 'E'
        },
        {
          label: this.$t('SUPPLIER_SEARCH.STAT_CHOICES.S'),
          value: 'S'
        },
        {
          label: this.$t('SUPPLIER_SEARCH.STAT_CHOICES.V'),
          value: 'V'
        },
      ],
      filteredMarket: [],
      gettingNego: false
    }
  },
  methods: {
    ...mapActions('supplier', ['fetchNegoGroup']),
    closeModal() {
      this.$emit('close-modal')
    },
    updateTypOfExtraction(typOfExtraction) {
      this.form.typOfExtraction = typOfExtraction
    },
    updateSendMode(value) {
      this.form.sendMode = value
    },
    updateFrequency(value) {
      this.form.frequency = value
    },
    updatePerimeter(value) {
      this.form.perimeter = value
    },
    updateStatusOfThePurchasePriceAverageColumn(value) {
      this.form.statusOfThePurchasePriceAverageColumn = value
    },
    updateSector(sector) {
      const newMarketList = this.markets.filter(market => market.parentSid === sector.sid);
      this.form.sectorId = sector.id;
      this.filteredMarket = newMarketList;
    },
    updateExtractionDay(dayOfExtraction) {
      this.form.dayOfExtraction = dayOfExtraction.value
    },
    async setMarketsList(marketsList) {
      if(Array.isArray(marketsList) && this.form.sectorId) {
        const joinMarkets = marketsList.join(',')
        this.form.marketsList = marketsList
        this.gettingNego = true;
        this.fetchNegoGroup({supplierCode: this.$route.query.id, sector: this.form.sectorId, markets: joinMarkets })
        .then(res => {
          this.gettingNego = false;
        },)
        .catch(err => {
          this.gettingNego = false;
        })

      }
    },
    setNegoList(negoList) {
      if(Array.isArray(negoList)) {
        this.form.negoGroupe = negoList
      }
    },
    emitSetting() {
      this.$emit(this.modalMode, {
        ...this.modalMode === 'edit' && { extractionid: this.setting.extractionid },
        ...this.modalMode === 'edit' && { createdDate: this.setting.createddate },
        ...this.modalMode === 'edit' && { createdBy: this.setting.createdby },
        sendMode: this.form.sendMode,
        sectorId: this.form.sectorId,
        supplierCode: this.form.supplierCode,
        frequency: this.form.frequency,
        dayOfExtraction: this.form.frequency === 'WEE' ? this.form.dayOfExtraction : null,
        perimeter: this.form.sendMode === 'PRT' ? this.form.perimeter : null,
        typOfExtraction: this.form.typOfExtraction,
        marketsList: this.form.marketsList.join(','),
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        extractionStatus: this.form.extractionStatus ? this.form.extractionStatus : null, //TODO
        statusOfThePurchasePriceAverageColumn: this.form.statusOfThePurchasePriceAverageColumn,
        negoGroupSids: this.form.negoGroupe.join(',')
      })
    },
    formValuesSetted() {
      if (!this.form.sendMode || !this.form.sectorId || !this.form.frequency || this.form.frequency === "WEE" && !this.form.dayOfExtraction || (this.form.sendMode === 'PRT' && !this.form.perimeter) || this.form.typOfExtraction.length === 0 || this.form.marketsList.length === 0 || !this.form.statusOfThePurchasePriceAverageColumn) {
        return false;
      }

      return true;
    }
  }
}
</script>
<style scoped lang="scss">
@import '~@/styles/_colors';
.spinner-container {
  display: inline-block;
  background-color: transparent !important;
  vertical-align: middle;
}
.spinner-container,
.button {
  position: relative;
  background-color: $maingreen;
  color: white;
  width: 80px;
  height: 40px;
  border: 0;
  margin: 0.5em;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}

.button:active {
  background: #007a63;
}

.button__text {
  // font: bold 20px "Quicksand", san-serif;
  color: #ffffff;
  transition: all 0.2s;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.spinner-container::after {
  border-top-color: $maingreen !important;
  box-shadow: 0 0 5px 0 rgb(141, 140, 140);
}

.spinner-container::after,
.button--loading::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner .8s ease-in infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.autocomplete {
  width: 100%;
}
.footer {
  margin-top: 1em !important;
}


.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  background: white;
  color: #333;
  position: fixed;
  top: 6%;
  width: 78rem;
  max-height: 92%;


  &-body {
    padding: 1rem 2rem;
    overflow-y: scroll;
    height: 450px;
    overflow-x: hidden;
  }

  &-header {
    background-color: $maingreen;
    padding: 0 1em;
    height: 4em;
  }
}

.icon {
  color: white;

  &:hover {
    cursor: pointer;
  }
}

.button[disabled]{
  &, &:active{
    background-color: #ccc;
  }
}
</style>
