import InjectBaseComponents from '@/components/base'
import { createApp as createVueApp, inject } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import store from './store'
import Forbidden from './Forbidden.vue'
import router from './router'
import vClickOutside from 'click-outside-vue3'
import Toaster from '@meforma/vue-toaster'
import '../node_modules/ag-grid-community/styles/ag-grid.css'
import http from '@/http'
/*import { computed, inject, reactive, ref } from 'vue'*/

export function createApp({ token, user, roles }) {
  const app = createVueApp(App)
    .provide('token', token)
    .provide('userInfos', user)
    .provide('userRoles', roles?.dataobs)
    .use(router)
    .use(store)
    .use(i18n)
    .use(vClickOutside)
    .use(Toaster)

  InjectBaseComponents(app)
  app.mount('#app')
}

export function createForbiddenApp({ token, userInfos, roles }) {
  const app = createVueApp(Forbidden).provide('token', token).provide('userInfos', userInfos).provide('roles', roles)

  InjectBaseComponents(app)
  app.mount('#app')
}
