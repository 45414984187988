import http from '@/http'
import { computed, inject, reactive, ref } from 'vue'

const state = {
    markets: [],
    sectors: []
  }

const  mutations = {
  setMarkets(state, markets) {
    state.markets = markets
  },
  setSectors(state, sectors) {
    state.sectors = sectors
  }
}

const actions = {
  async fetchMarkets({ state, commit, rootState}) {
    let markets
    const searchParams = {

    }

    try {
      markets = await http
      .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
      .get(`nomenclature/markets`, { searchParams })
      .json()
    } catch (error) {
      sendErrorToast('Erreur lors de la récupération des paramètres')
    }
    commit('setMarkets', markets)
  },

  async fetchSectors({ state, commit, rootState}) {
    let sectors

    const searchParams = {

    }

    try {
      sectors = await http
      .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
      .get(`nomenclature/sectors`, { searchParams })
      .json()
    } catch (error) {
      // sendErrorToast('Erreur lors de la récupération des paramètres')
    }
    commit('setSectors', sectors)
  },
}

const getters = {
  markets(state) {
    return state.markets
  },
  sectors(state) {
    return state.sectors
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
