
  <template>
  <div>
    <div class="flex justify-space">
      <h3>{{ title }}</h3>
      <v-chip v-if="error" :label="error" :icon="'warning'" :color="'error'" :is-small="true" />
    </div>
    <div class="input-modal flex justify-start" :class="error.length ? 'error' : 'input-modal-blue'">
      <v-svg class="icon ml-1" :name="'mail'" size="16px"></v-svg>
      <input
        type="text"
        :placeholder="placeholder"
        :disabled="disabled"
        v-model="value"
        @keyup="checkForm(value)"
      />
    </div>
    <div class="text-error" v-if="error">
      {{ error }}
    </div>
    <div class="label" v-if="label.length">
      {{ label }}
    </div>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg'
import VChip from '@/components/Chip'

  export default {
    name: 'InputModal',
    components: { 
      VSvg, 
      VChip 
    },
    props: {
      /**
       * Input title
      */
      defaultValue: {
        type: String,
        default: ''
      },
      /**
       * Input title
      */
      title: {
        type: String,
        default: ''
      },
      /**
       * Input placeholder
      */
      placeholder: {
        type: String,
        default: function () {
          return this.$t('search')
        }
      },
      /**
       * Input label
      */
      label: {
        type: String,
        default: ''
      },
      /**
       * is disabled
      */
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        value: this.defaultValue,
        error: ''
      }
    },
    methods: {
      checkForm() {
        this.error = ''

        if (!this.validEmail(this.value)) {
          this.error = this.$t('error.email.invalid')
        }

        this.$emit('input', this.value)
      },
      validEmail(email) {
        let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        return re.test(email)
      }
      }
    }
</script>

<style scoped lang="scss">
@import "~@/styles/_colors";

  .input-modal {
    border-radius: 0.8rem;
    
    &.error {
      background-color: #EDE0E1;
      color: $error;

      > input {
        background-color: #EDE0E1;
        color: $error;

        &::placeholder {
          color: $error;
        }
      }
    }

    &-blue {
      background-color: $lightblue;
      color: $blue;

      > input {
        background-color: $lightblue;
        color: $blue;
        
        &::placeholder {
          color: $blue;
        }
      }
    }
  }
  
  .input-modal input { 
    border: 0;
    padding-left: 1rem;
    height: 4.4rem; 
    outline: none;
    width: 100%;
    border-radius: 0.8rem;
  }  

  .right-inner-addon {
    position: relative;
  }
  .right-inner-addon input {
    padding-right: 3rem;
  }

  .text-error {
    color: $error;
    padding: 1rem 0;
  }

  .label {
    padding: 1rem 0 2rem 0;
  } 
</style>