<template>
    <div class="toogle-container">
      <span class="toggle__label">{{ labelEnableText }}</span>
      <label :for="id + '_button'" :class="{'active': currentState}" class="toggle__button">
          <span class="toggle__switch"></span>
          <input type="checkbox" :disabled="disabled" :id="id + '_button'" v-model="checkedValue">
      </label>
      <span class="toggle__label">{{ labelDisableText }}</span>
    </div>
</template>

<script>
  export default {
    name: 'toogle-btn',
    props: {
      /**
       * Is the btn usable
      */
      disabled: {
        type: Boolean,
        default: false
      },
      /**
       * enabel position label
      */
      labelEnableText: {
        type: String
      },
      /**
       * disable position label
      */
      labelDisableText: {
        type: String
      },
      /**
       * ToogleBtn ID
      */
      id: {
        type: String,
        default: 'toogle'
      },
      /**
       * start position
      */
      defaultState: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        currentState: this.defaultState
      }
    },
    computed: {
        checkedValue: {
            get() {
                return this.currentState
            },
            set(newValue) {
                this.currentState = newValue
                this.$emit('input', newValue)
            }
        }
    }
  };
</script>

<style scoped lang="scss">
@import "~@/styles/_colors";

.toogle-container {
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.toggle__button {
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
}

.toggle__button input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    width: 0.1rem;
    height: 0.1rem;
}

.toggle__button .toggle__switch {
    border: 1px solid grey;
    display:inline-block;
    height: 2.4rem;
    border-radius:1.2rem;
    width:4.8rem;
    box-shadow: inset 0 0 1px #BFCBD9;
    position:relative;
    margin: 0 1rem;
    transition: all .25s;
    top: 2px;
}

.toggle__button .toggle__switch::after,
.toggle__button .toggle__switch::before {
    content: "";
    position: absolute;
    top: 10rem;
    display: block;
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 50%;
    left: 2px;
    top: 3px;
    transform: translateX(0);
    transition: all .20s cubic-bezier(.5, -.6, .5, 1.6);
}

.toggle__button .toggle__switch::after {
    background: #4D4D4D;
    box-shadow: 0 0 1px #666;
}

.active .toggle__switch {
    background-color: $pink;
    border: 1px solid $pink;
    box-shadow: inset 0 0 1px $lightblue;
}

.active .toggle__switch::after,
.active .toggle__switch::before{
    transform:translateX(4rem - 1.4rem);
}

.active .toggle__switch::after {
    background: white;
    box-shadow: 0 0 1px $blue;
}
.toggle__label {

}
</style>
