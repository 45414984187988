<template>
  <div class="card-container">
    <div class="card-header">
      <span class="text-bold">[<span class="text-teal">{{supplierID}}</span>]</span><br>
      <div class="truncate">
        <span class="text-bold text-big">{{ supplierLabel }}</span>
      </div>
    </div>

    <div class="card-body">
      <div class="card-title">
        <div class="flex align-center">
          <v-svg class="icon text-teal text-bold mr-2" name="send" size="16px"></v-svg>
          <span class="text-teal text-bold" style="margin-left: -0.8em; font-size:16px;">{{ $t('SUPPLIER_SEARCH.SEND_MODES.TITLE') }}</span>
        </div>
        <div class="flex flex-wrap" style="margin-left: 1.5em;" v-if="typesOfSendModes.length">
          <tag v-for="(sendMode, index) in typesOfSendModes" :key="index" :tag="sendMode" direction="row"/>
        </div>
        <div class="" v-else>
          {{ $t('SUPPLIER_SEARCH.SEND_MODES.NO_DATA') }}
        </div>
      </div>
      <div class="card-markets">
        <div class="card-title">
          <div class="flex align-center">
            <v-svg class="icon text-teal text-bold mr-2" name="shopping-cart" size="16px"></v-svg>
            <span class="text-teal text-bold" style="margin-left: -0.8em; font-size:16px;">{{ $t('SUPPLIER_SEARCH.MARKETS') }}</span>
          </div>

          <div v-if="marketsList.length" style="margin-left: 1.5em;font-size: .85em;">
            <ul>
              <li v-for="(market, index) in filteredMarkets" :key="index">[{{ market.id }}] {{ market.label }}</li>
            </ul>
          </div>
          <div class="" v-else>{{ $t('SUPPLIER_SEARCH.NO_MARKETS') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg'
import Tag from '@/components/Tag'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SupplierCard',
  components: {
    VSvg,
    Tag
  },
  props: {
    supplierID: {
      type: String,
      default: '123456'
    },
    typesOfSendModes: {
      type: Array,
      default: () => []
    },
    marketsList: {
      type: Array,
      default: () => []
    },
    supplierID: {
      type: String,
      default: ''
    },
    supplierLabel: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapGetters('nomenclature', ['markets']),
    filteredMarkets() {
      return this.marketsList.split(',').map(sMarket => {
        const m = this.markets.find(market => sMarket.toString() === market.id.toString())
        return {
          id: m ? m.id : sMarket,
          label: m ? m.label : this.$t('SUPPLIER_SEARCH.UNKNOWN_MARKET')
        }
      })
    },
  },
  data() {
    return {
    }
  },
  methods: {
    beforeEnter: function (el) {
      el.style.height = '0'
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave: function (el) {
      el.style.height = '0'
    },
    showCard() {
      this.show = !this.show
      this.$emit('showCard', this.show)
    },
    getMarketLabel(id) {
      const m = this.markets.find(m => m.sid === id)
      return m ? m.label : this.$t('SUPPLIER_SEARCH.UNKNOWN_MARKET')
    }
  }
}
</script>

<style lang="scss">
.card-container {
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.29);
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.29);

  background-color: white;
  border-radius: 3px;
  padding: 2em;
  margin: 0.5em;
  font-size: .9em;
  width: 31%;
  cursor: pointer;
  .card-title {
    // margin: 2em 0 2em 0;
    margin-top: 2em;
  }

  .card-header {
    // padding: 2em 1.5rem 0 2rem;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
