<template>
  <div class="supplier-search">
    <div class="header">
      <autocomplete @set-search="setSearch"
        :items="searchResults"
        placeholder="Recherche par fournisseur, marché..."
      />
    </div>
    <div class="body body-container" v-if="search?.length > 0">
      <div class="body-menu">
        <div class="flex align-center">
          <v-svg class="icon text-teal text-bold mr-2" name="send" size="16px"></v-svg>
          <span class="text-teal text-bold" style="margin-left: -0.8em;font-size:16px;">{{ $t('SUPPLIER_SEARCH.SEND_MODES.TITLE') }}</span>
        </div>
        <div class="" style="margin-left: 1.5em; display: flex;
         flex-direction: inherit">
          <tag v-for="(value, name) in totalSendModes" :key="name" :tag="`${name}`" :occurences="value" direction="row"/>
        </div>

        <div class="flex align-center mt-3">
          <v-svg class="icon text-teal text-bold" name="shopping-cart" size="16px"></v-svg>
          <span class="text-teal text-bold" style="margin-left: 0.5em; font-size:16px;">{{ $t('SUPPLIER_SEARCH.MARKETS') }}</span>
        </div>
        <div class="flex flex-column" style="margin-left: 2.7em;text-transform: lowercase;">
          <span class="flex justify-space text-small mt-2 mb-1"
                v-for="(value, name) in totalMarkets"
                :key="name"> {{ name }} <span class="textbold">[{{ value }}]</span> </span>
        </div>
      </div>
      <div class="body-content">
        <div class="body-title flex justify-space align-center">
          <span class="text-bold text-big" style="font-weight: 100;">{{ search?.length }} {{ $t('SUPPLIER_SEARCH.RESULT') }}{{search?.length > 1 ? 's' : ''}}</span>
          <v-button :icon="'export'" @action="exportSupplierSettings"/>
        </div>
        <div class="body-cards flex wrap" >
            <supplier-card
              :supplierID="supplier.id"
              :supplier-label="supplier.name"
              :types-of-send-modes="getDistinctValues(supplier.settings, 'sendmode')"
              :markets-list="supplier.markets"
              v-for="(supplier, index) in selectedSuppliers"
              :key="index"
              @click="redirectDetail(supplier.id)"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import Autocomplete from '@/components/Autocomplete.vue'
import SupplierCard from '@/components/SupplierCard.vue'
import Tag from '@/components/Tag.vue'
import VButton from '@/components/Button.vue'
import { mapActions, mapGetters } from 'vuex'
import { getDistinctValues, getOccurences } from '../utils/utils'
import { exportXls } from '@/utils/xls'

export default {
  name: 'SupplierSearch',
  components: {
    Autocomplete,
    SupplierCard,
    Tag,
    VButton
  },
  data() {
    return {
      search: [],
      selectedMarkets: [],
      tags: ['E', 'V']
    }
  },
  computed: {
    ...mapGetters('nomenclature', ['markets']),
    ...mapGetters('supplier', ['suppliers']),
    searchResults() {

       return this.suppliers?.length
           ?  this.suppliers.map(s => {
             return {
               label: `${s.id} ${s.name}`,
              el: s
             }
           })
         : []

    },
    selectedSuppliers() {
      return this.search.map(s => {
        return {
          ...s.el,
          markets: this.getAllSupplierMarkets(s.el.settings)
        }
      })
    },
    totalSendModes() {
      let occurences = []

      this.selectedSuppliers.forEach(s => {
        occurences = occurences.concat(this.getDistinctValues(s.settings, 'sendmode'))
      })

      return this.getOccurences(occurences)
    },
    totalMarkets() {
      const allMarketsIds = this.selectedSuppliers.map(s => s.markets.split(',')).flat().filter(Boolean)
      const allMarketsLabels = this.markets.filter(m => allMarketsIds.some(id => id.toString() === m.id.toString())).map(el => el.label)
      return this.getOccurences(allMarketsLabels)
    }
  },
  methods: {
    ...mapActions('nomenclature', ['fetchMarkets']),
    ...mapActions('supplier', ['fetchSuppliers']),
    getDistinctValues: getDistinctValues,
    getOccurences: getOccurences,
    getAllSupplierMarkets(s) {
      return [...new Set(s.map(s => s.marketslist.split(',')).flat())].join(',')
    },
    setSearch(value, isMarket) {
      if (isMarket) value.el = this.markets.find(m => m.id.toString() === value.id.toString())
      else this.search = value
    },
    async exportSupplierSettings() {
      let dataForExcel = [];
      let temp = []

      const today = new Date(Date.now()).toLocaleString().split(',')[0].replace(/\//g, '')
      const fileName = today + `_Suppliers`
      const allSettings = this.selectedSuppliers.map(s => s.settings)

      allSettings.forEach(s => {
        s.forEach(s2 => {
          temp.push(s2)
        })
      })

      temp.forEach(row => {
        const clonedRow  = Object.assign({}, row);
        clonedRow.sendMode = this.$t(`SUPPLIER_SEARCH.SEND_MODES.${clonedRow.sendMode}`)
        clonedRow.frequency = this.$t(`SUPPLIER_SEARCH.FREQUENCY.${clonedRow.frequency}`)
        clonedRow.dayOfExtraction = this.$t(`SUPPLIER_SEARCH.EXTRACTION_DAY.${clonedRow.dayOfExtraction}`)
        clonedRow.perimeter = this.$t(`SUPPLIER_SEARCH.CONSOLIDATION.${clonedRow.perimeter}`)
        clonedRow.typOfExtraction = this.$t(`SUPPLIER_SEARCH.CONSOLIDATION.${clonedRow.typOfExtraction}`)
        clonedRow.marketsList = this.$t(`SUPPLIER_SEARCH.CONSOLIDATION.${clonedRow.marketsList}`)
        clonedRow.startDate = clonedRow.startDate
        clonedRow.endDate = clonedRow.endDate
        clonedRow.createdDate = clonedRow.createdDate ? clonedRow.createdDate : ''
        clonedRow.updatedDate = clonedRow.updatedDate ? clonedRow.updatedDate : ''
        clonedRow.extractionStatus = clonedRow.extractionStatus
        dataForExcel.push(Object.values(clonedRow))
      })

      let reportData = {
        title: fileName,
        data: dataForExcel,
        headers: Object.getOwnPropertyNames(temp[0])
      }

      exportXls(reportData)
    },
    redirectDetail(id) {
      this.$router.push({
        path: 'supplier-detail',
        query: {
          id
        }
      })
    },
  },
  mounted() {
    this.fetchMarkets()
    this.fetchSuppliers()
  }
}
</script>

<style scoped lang="scss">
.body-container {
  display: flex;
  margin-top: 1em;
  width: 100%;
}

.body-menu {
  -webkit-box-flex: 1;
  flex: 1 1 100%;
  max-width: 20%;
  height: fit-content;
  padding: 3em 1em 10em 2em;
  box-sizing: border-box;
  border-right: 1px solid $lightgrey;

  .tags {
    margin-left: 2.9em;
  }
}

.body-content {
  flex-wrap: wrap;
  margin: 1em;
  width: 100%;
}

.body-cards {
  margin: 1em;
  overflow-y: scroll;
  height: 373px;
}

.body-title {
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #009688;
}
</style>
