<template>
  <div id="app">
    <Navbar v-if="!currentUrl.endsWith('/')" />
    <div :class="{ wrapper: !currentUrl.endsWith('/') }">
      <Notification v-if="notifications.length" :notifications="notifications" />
      <router-view />
    </div>
  </div>
</template>

<script>
  import Navbar from '@/components/v2/navbar/NavBar.vue'
  import Notification from '@/components/Notification.vue'
  import { mapActions, mapGetters } from 'vuex'
  import { getFirestore, collection, getDocs, where, query } from 'firebase/firestore'

  export default {
    name: 'app',
    components: {
      Navbar,
      Notification
    },
    data() {
      return {
        currentUrl: window.location.href,
        notifications: []
      }
    },
    computed: {},
    methods: {
      ...mapActions('global', ['fetchUser'])
    },
    created() {
      const biAuchan = document.getElementById('BI-AUCHAN')
      biAuchan ? (biAuchan.style.display = 'none') : ''
      document.getElementById('loader').style.display = 'none'
      document.body.style.backgroundColor = '#ffffffe1'
      this.fetchUser()
    },
    async mounted() {
      const db = getFirestore()
      const COLLECTION_NAME = 'NOTIFICATIONS'
      const q = query(collection(db, COLLECTION_NAME), where('end', '>', new Date()))

      const querySnap = await getDocs(q)
      querySnap.forEach(doc => {
        const data = doc.data()
        if (data.start.toDate() <= new Date()) {
          this.notifications.push(doc.data())
        }
      })
    }
  }
</script>
<style>
  .wrapper {
    padding: 80px 32px;
    height: 100%;
  }
</style>
