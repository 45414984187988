<template>
  <div class="ul-tags" v-if="tag.length >0">
    <div class="li-tag">
      <a :class="`tag ${tag}`">{{ $t(`TAG.${tag}`) }} <span v-if="occurences">[{{ occurences }}]</span></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    tag: {
      type: String,
      default: ''
    },
    occurences: {
      type: String,
      default: ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/styles/_colors";

//Tag style
.ul-tags {
  min-height: 46px;
  overflow: hidden;
  list-style: none;
  margin: 0;
  overflow: hidden;
  // margin-left: 1.7em;
  padding-top: .7em;

  .li-tag {
    float: left;
    margin-left: 0;
    margin-top: 0;
    .tag {
      background: #ddd;
      border-radius: 3px 0 0 3px;
      color: white;
      display: inline-block;
      height: 26px;
      line-height: 26px;
      height: 26px;
      font-weight: bold;
      padding: 0 20px 0 23px;
      position: relative;
      margin: 0 10px 10px 0;
      text-decoration: none;
      font-size: 1em;
    }
    .tag::after {
      background: #fff;
      border-bottom: 12px solid transparent;
      border-left: 10px solid #ddd;
      border-top: 14px solid transparent;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
    }

    .tag::before {
      background: #fff;
      border-radius: 10px;
      box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
      content: '';
      height: 6px;
      left: 10px;
      position: absolute;
      width: 6px;
      top: 10px;
  }
  }
}

</style>
