<template>
  <div class="custom-select" @blur="open = false" :style="{ width }" v-click-outside="onClickOutside" @click="open = !open">
    <div class="selected" :class="{ open }">
      <span v-for="(item, index) in listItem" :key="index">{{ item.label }}<span v-if="index !== listItem.length -1">, </span> </span>
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <v-checkbox 
        class="item"
        v-model="selectedItems"
        v-for="(option, i) of options"
        :value="option.id.toString()" 
        :label="option.label" 
        :in-select="true"
        :key="i"
        @set-value="setValues"
      />
      </div>
  </div>
</template>

<script>
import VCheckbox from '@/components/Checkbox'
import vClickOutside from 'click-outside-vue3'

export default {
  name: 'VSelectCheckbox',
  components: {
    VCheckbox
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    defaultStateId: {
      type: Array,
      default: []
    },
    width: {
      type: String,
      default: null
    }, 
    selectName: {
      type: String,
      default: null
    }, 
    defaultSector: {
      type: Number,
      default: null
    },
    
  }, 
  data() {
    return {
      selectedItems: this.defaultStateId ? this.options?.filter(item => this.defaultStateId.includes(item.id.toString() ?? item.sid.toString())).map(v => v.id.toString() ?? v.sid.toString() ) : [],
      open: false,
    };
  },
  created() {
    
  },
  computed: {
    listItem(){
      return this.options?.filter(item => this.selectedItems.includes(item.id.toString() ?? item.sid.toString() ))
    }
  },
  methods: {
    setValues(values) {
      this.selectedItems = values.map(v => v.toString())
      this.$emit('input', this.selectedItems)
    },
    onClickOutside() {
      this.open = false
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/_colors"; 

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;
  cursor: pointer;
}

.selected {
  background-color: white;
  border-radius: 6px;
  // color: $darkblue;
  padding-left: 8px;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid $lightgrey;
}

.selected.open{
  border-bottom: 1px solid $lightgrey;
  border-radius: 6px 6px 0px 0px;
}

.selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 10px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-color: $blue transparent transparent transparent;
}

.items {
  color: white;
  z-index: 1;
  border-radius: 0px 0px 6px 6px;
  overflow: scroll;
  height: 200px;
  // border-right: 1px solid $darkblue;
  // border-left: 1px solid $darkblue;
  border-bottom: 1px solid $lightgrey;
  position: absolute;
  background-color: white;
  left: 0;
  right: 0;
}

.item{
  color: black;
  cursor: pointer;
  user-select: none;
}

.item:hover{
  color: white;
  background-color: $maingreen;
  color: #E0E3F8;
}

.selectHide {
  display: none;
}
</style>