<template>
  <div class="chip flex" :class="[isSmall ? 'chip-small': '', color ? `chip-${color}` : '']">
    <v-svg v-if="icon" class="icon" :name="icon" :size="isSmall ? '11px' : '16px'" ></v-svg>
    <label :class="isSmall ? '' : 'text-bold'">{{ label }}</label>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg'

export default {
  name: 'VChip',
  components: {
    VSvg
  },
  props: {
    /**
     * chip's text
    */
    label: {
      type: String,
      default: ''
    },
    /**
     * chip's icon
    */
    icon: {
      type: String,
      default: ''
    },
    /**
     * chip's color
    */
    color: {
      type: String,
      default: ''
    },
    /**
     * chip's size
    */
    isSmall: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/_colors";

.chip {
  border-radius: 8px;
  padding: 0.6em 1em 0.6em 1em;
  height: fit-content;
  width: fit-content;

  &-small {
    font-size: 12px;
    padding: 0.2em 0.5em 0.2em 0.5em;
  }

  &-pink {
    background-color: $pink;
    color: white;
    fill: white;
  }

  &-blue {
    background-color: $blue;
    color: white;
    fill: white;
  }

  &-lightpink {
    background-color: $lightpink;
    color: $pink;
    fill: $pink;
  }

  &-lightblue {
    background-color: $lightblue;
    color: $blue;
    fill: $blue;
  }

  &-error {
    background-color: rgba($error, 0.2);
    color: $error;
    fill: $error;
  }
}

.icon {
  padding-right: 8px;
}
</style>
