<template>
  <div class="notifications">
    <span v-for="(notif, i) in notifications" :key="i" v-show="i === currentIndex">
      {{ notif.text }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'Notification',
    props: {
      notifications: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        currentIndex: 0
      }
    },
    mounted() {
      this.startScrolling()
    },
    methods: {
      startScrolling() {
        setInterval(this.nextNotification, 3000)
      },
      nextNotification() {
        this.currentIndex = (this.currentIndex + 1) % this.notifications.length
      }
    }
  }
</script>

<style scoped lang="scss">
  .notifications {
    width: 100%;
    background-color: #dc3545;
    color: white;
    border-radius: 0.5em;
    padding: 0.7em 2em;
  }
</style>
