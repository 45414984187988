import i18n from '../i18n'

export const columnRvfDefs = [
  {
    field: 'fileName',
    headerName: i18n.global.t(`RVF.FILE`),
    width: 320
  },
  {
    field: 'rayon',
    headerName: i18n.global.t(`RVF.RAYON`),
    width: 230
  },
  {
    field: 'paymentSupplierCode',
    headerName: i18n.global.t(`RVF.PaymentCNUF`),
    width: 260
  },
  {
    field: 'paymentNegotiationGroupCode',
    headerName: i18n.global.t(`RVF.PaymentGDN`),
    width: 220
  },
  {
    field: 'type',
    headerName: i18n.global.t(`RVF.MovementTYPE`),
    width: 120,
    valueFormatter: params => i18n.global.t(`RVF.${params.value}`).toUpperCase()
  },
  {
    field: 'download',
    headerName: '',
    cellRenderer: 'iconCellRenderer',
    cellRendererParams: {
      iconName: 'export'
    },
    width: 50,
  }
]
