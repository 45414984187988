<template>
  <div>
    <div v-if="dataTab.length !== 0" class="data-grid">
     <Loading :isLoading="isLoad"></Loading>
      <div class="table">
        <div class="thead">
          <div class="tr">
            <span v-for="(col, key) in columns"  :class="[inArray(key,isHiddenColumns)?'hide-td':'td',!isRVF? 'td-right':'']" :key="key" @click="sortBy(key)" >
              {{ col }}
            </span>
          </div>
        </div>
        <div class="tbody">
          <div
            @click="$emit('action-row', key)"
            :class="['tr', key & 1 ? 'even' : 'odd', isClickable ? 'clickable' : '']"
            v-for="(row, key) in tab"
            :key="key"
          >
            <span   v-for="(column, name,key) in row" :key="name" v-html="column"  :class="[inArray(key,isHiddenColumns)?'hide-td': name==this.orderName  ? 'td bold' : 'td',!isRVF? 'td-right':'']" />

            <span class="td" v-if="isClickable"><v-svg name="chevron-right" :size="'15px'" /></span>
            <span  class="td" v-if="isEditable">
<!--              <v-svg class="pencil opacity" name="pencil" :size="'20px'" @click="$emit('edit-element', row, key)" v-if="this.isManager(row,key)"/>-->
              <v-button   @click="$emit('edit-element', row, key)" v-if="this.isManager(row,key)" >  <v-svg class="edit" name="pencil"  /></v-button>
            </span>
            <button type="button" v-if="isExport" style="padding: 7px;border: none;background: none; cursor: pointer" v-on:click="downloadCSVData('DATAOBS/PRT/'+row.e,row.e)">
              <v-svg class="edit" name="export"  />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="empty-grid flex mt-2 ml-2" v-else>
      {{ $t('SUPPLIER_SEARCH.NO_RESULTS') }}
    </div>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg.vue'
import {  mapActions } from 'vuex'
import Loading from '@/components/Loading.vue'
import { saveAs } from 'file-saver'

export default {
  name: 'DataGrid',
  components: {
    VSvg,
    Loading
  },
  props: {
    /**
     * data tab
     */
    dataTab: {
      type: Array,
      default: () => []
    },
    /**
     * column tab
     */
    columns: {
      type: Array,
      default: () => {}
    },
    /**
     * message when no datas
     */
    noDatasLabel: {
      type: String,
      default: function () {
        // return this.$t('SUPPLIER_SEARCH.RESULTS')
        return ''
      }
    },
    /**
     * is the line clickable
     */
    isClickable: {
      type: Boolean,
      default: false
    },
    /**
     * add edit and delete
     */
    isEditable: {
      type: Boolean,
      default: false
    }
    ,
    /**
     * add edit and delete
     */
    isExport: {
      type: Boolean,
      default: false
    },
    isHiddenColumns:{
      type: Array,
      default:[]
    },
    manager:{
      type:String,
      default:''
    },
    isRVF:{
      type:Boolean,
      default: false
    },
    isLoading:{
      type:Boolean,
      default: false
    }
  },
  data() {
    return {
      tab: this.dataTab,
      prevKey: '',
      orderName: 'Nom',
      orderDesc: -1,
      columnFromKey: [
        { name: 'IFLS', value: 0 },
        { name: 'Nom', value: 1 },
        { name: 'Email', value: 2 },
        { name: 'Profile', value: 3 },
        { name: 'Mot', value: 4 },
        { name: 'Responsable', value: 5 },
      ],
      isLoad: this.isLoading
    }
  },
  methods: {
    ...mapActions('supplier', ['fetchRVFSales','downloadFileRVF']),
    sortBy(key) {
      key=this.columnFromKey.find(c => c.value === key).name
      this.orderName= key
      if (this.prevKey === key) {
        let vue = this
        this.tab.sort(function compare(a, b) {
          if (a[key] < b[key]) return -1 * vue.orderDesc
          if (a[key] > b[key]) return 1 * vue.orderDesc
          return 0
        })
        this.orderDesc = this.orderDesc * -1
      } else {
        this.tab.sort(function compare(a, b) {
          if (a[key] < b[key]) return -1
          if (a[key] > b[key]) return 1
          return 0
        })
        this.prevKey = key
        this.orderDesc = this.orderDesc * -1
      }
    },
    inArray: function(element, arrayList) {
      var length = arrayList.length;
      for(var i = 0; i < length; i++) {
        if(arrayList[i] == element) return true;
      }
      return false;
    },
    isManager: function(row, key) {
      return (row.Responsable.includes(this.manager.uid))?true:false;
    },
    async downloadCSVData(file, nameFile) {
      let csvdata =  await this.downloadFileRVF({nameFile: file})
      saveAs(csvdata.file[0], nameFile)
    }
  },
  mounted() {
    this.tab = this.dataTab
    this.isLoad = this.isLoading

  },
  watch: {
    dataTab(val) {
      this.tab = val
      //this.sortBy(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.odd {
  background-color: white;
}
.even {
  background-color: $background;
}
.bold {
  font-weight: 700;
}
.data-grid {
  margin: 10px auto;
  padding: 2px;
  overflow: hidden;
}
.table {
  background-color: rgb(0,150,136);
  width: 100%;
  font-size: 15px;
  color: black;
  overflow-y: scroll;
  max-height: 390px;

}
.thead {
  display: table-header-group;
}
.tbody {
  display: table-row-group;
}
.tr {
  display: table-row;
  border-radius: 8px;
  height: 48px;
}
.td {
  display: table-cell;
  position: relative;
  padding: 4px 16px;
  vertical-align: middle;

}
.td-right{
  text-align: right;
}
.thead .td {
  font-weight: bold;
  cursor: pointer;
  color: white;
}

.empty-grid {
}

.clickable:hover {
  opacity: 0.5;
  cursor: pointer;
  background-color: #c5cae9;
}
.pencil {
  /* color: $blue;*/
  padding-right: 3.5rem;
  color: white;
  top: 11px;
  position: absolute;
}
.trash {
  color: $pink;

}
.edit {
  background-color: $maingreen;
  border-radius: 50%;
  color: rgb(255,255,255);
  /*width: 4.4rem;*/
  height: 3.6rem!important;
  padding: 10px;
  left: -44px;
}
.export-RVF {
  background-color: $maingreen;
  color: rgb(255,255,255);
  border-radius: 50%;
  width: 4.4rem;
  height: 3.6rem !important;
  padding: 10px;

}
.hide-td{
  display: none;
}

</style>
