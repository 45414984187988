<template>
  <div class="navbar">
    <a href="/" class="logo">
      <img src="@/assets/logos/dataObs.png" />
    </a>
    <span class="version-name">{{ getVersion() }}</span>
    <nav>
      <router-link v-for="menu of menus" :key="menu.name" class="application" :to="menu.link">
        <NavbarItem :name="menu.name" />
      </router-link>
      <div class="date-container">
        <select v-model="selectedYear" class="date-select" :disabled="$route.name === 'vsupplierdetail'" @change="onChange($event)">
          <option v-for="(item, index) in campaignYears" :key="index" :value="item.year">
            {{ item.year }}
          </option>
        </select>
      </div>
    </nav>
    <div class="navRight">
      <nav>
        <span class="user-display-name">{{ userInfos.displayName ?? userInfos.email }}</span>
        <NavbarItem class="column" :icon-name="'logout'" @click="logout()" />
      </nav>
    </div>
  </div>
</template>

<script>
  import NavbarItem from './NavBarItem.vue'
  import { logout } from '../../../main.js'
  import { mapActions, mapGetters } from 'vuex'
  import { globalProperties } from '@/main'
  import http from '@/http'

  export default {
    name: 'Navbar',
    components: {
      NavbarItem
    },
    data() {
      return {
        currentUrl: window.location.href,
        allMenus: [
          { name: this.$t(`MENU.RECHERCHE_SUPPLIERS`), link: this.setLocationUrl('/supplierSearch') },
          { name: this.$t(`MENU.RVF`), link: this.setLocationUrl('/rvf') }
        ],
        menus: [],
        campaignYears: [],
        selectedYear: '',
        MAX_YEARS_TO_SHOW: 6
      }
    },
    async created() {
      const url = window.location.href.toLowerCase()
      if (url.includes('v2')) this.allMenus.unshift({ name: this.$t(`MENU.INFOS_SUPPLIERS`), link: this.setLocationUrl('/infos') })
      await this.fetchCampaignYears()
    },
    computed: {
      ...mapGetters('global', ['userInfos', 'userRoles', 'year'])
    },
    mounted() {
      this.setMenus()
    },
    methods: {
      ...mapActions('global', ['fetchCampaignYears']),
      getVersion() {
        const url = window.location.href.toLowerCase()
        return url.includes('v2') ? 'V2' : 'V1'
      },
      logout() {
        logout()
      },
      setLocationUrl(route) {
        const url = window.location.href.toLowerCase()
        return url.includes('v2') ? `/v2${route}` : route
      },
      setMenus() {
        this.menus =
          this.userRoles?.includes('manager') || this.userRoles?.includes('user')
            ? this.allMenus
            : [{ name: this.$t(`MENU.RVF`), link: this.setLocationUrl('/rvf') }]
      },
      onChange(event) {
        this.$store.commit('global/setYear', event.target.value)
      },
      async fetchCampaignYears() {
        try {
          const token = await globalProperties?.firebaseUser?.getIdToken()
          this.campaignYears = await http
            .extend({ headers: { Authorization: `Bearer ${token}` } })
            .get(`referential/campaignYears`)
            .json()
        } catch (error) {
          console.error('Erreur lors de la récupération campaignYears : ' + error)
        }
        if (this.campaignYears.length) {
          this.selectedYear = this.campaignYears[0].year
          this.$store.commit('global/setYear', this.selectedYear)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .date-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
    flex-grow: 1;
    align-items: center;
  }
  .date-select {
    border: none;
    background: white;
    border-radius: 1rem;
    height: 2.1rem;
  }
  .navbar {
    position: fixed;
    top: 0;
    z-index: 100;

    background: linear-gradient(90deg, white 70%, $primary 99.91%);
    border-radius: 0 0 15px 15px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid $primary;
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 2em;

    .logo {
      cursor: pointer;
      color: $primary;
    }

    .version-name {
      margin-right: 30px;
      font-weight: bold;
      color: $primary;
    }

    nav {
      display: flex;
      width: 100%;
      height: 100%;
      font-size: 12px;

      a:hover {
        text-decoration: none;
      }

      a:focus {
        text-decoration: none;
      }

      a {
        font-weight: bold;
        margin: 0 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
        color: $darkGrey;

        &:hover {
          color: $primary;
          border-bottom: 3px solid $primary;
        }

        &.router-link-exact-active {
          color: $primary;
          border-bottom: 3px solid $primary;
        }
      }
    }
  }

  .user-display-name {
    color: #fff;
    white-space: nowrap;
    font-size: 1.2em;
    line-height: 3.5em;
    font-weight: bold;
    padding-top: 5px;
  }

  .navRight {
    nav .column {
      margin: 0 10px;
      font-weight: bold;
      color: white;
      font-size: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
