<template>
  <div class="supplier-search">
    <Loader :is-loading="showLoader" />
    <div class="header">
      <autocomplete placeholder1="Recherche par CNUF/libellé CNUF de paiement" placeholder3="Recherche par GDN de paiement" @set-search="setSearch" />
    </div>
    <div class="body body-container">
      <div class="body-content">
        <div class="body-title flex justify-space align-center">
          <span class="text-bold text-big" style="font-weight: 100"
            >{{ suppliers?.length }} {{ $t('SUPPLIER_SEARCH.RESULT') }}{{ suppliers?.length > 1 ? 's' : '' }}</span
          >
          <v-button v-if="suppliers?.length" :icon="'export'" @action="exportSupplierSettings" />
        </div>
        <div class="body-cards flex wrap scrollbar" :class="suppliers?.length > 2 && 'arround22'">
          <supplier-card v-for="(supplier, index) in suppliers" :key="index" :supplier="supplier" @click="redirectDetail(supplier)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import Autocomplete from '@/components/v2/Autocomplete.vue'
  import SupplierCard from '@/components/v2/SupplierCard.vue'
  import Tag from '@/components/Tag.vue'
  import VButton from '@/components/Button.vue'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { getDistinctValues, getOccurences } from '../../utils/utils'
  import { exportXls } from '@/utils/xls'
  import Loader from './Loader'

  export default {
    name: 'SupplierSearch',
    components: {
      Loader,
      Autocomplete,
      SupplierCard,
      Tag,
      VButton
    },
    data() {
      return {
        searchResult: [],
        tags: ['E', 'V'],
        showLoader: false,
        searchRequested: false,
        selectedFilter: null
      }
    },
    computed: {
      ...mapGetters('npasupplier', ['suppliers']),
      ...mapGetters('global', ['userInfos', 'userRoles']),
      ...mapState('global', ['year']),
      ...mapState('searchFilters', ['cnuf', 'rayon', 'gdn']),
      totalSendModes() {
        let occurences = []

        this.searchResult.forEach(s => {
          occurences = occurences.concat(this.getDistinctValues(s.settings, 'sendmode'))
        })

        return this.getOccurences(occurences)
      }
    },
    watch: {
      year() {
        this.fetchData({ purchaser: this.userRoles.includes('manager') ? '' : this.userInfos.uid, year: this.year, ...this.selectedFilter })
      }
    },
    methods: {
      ...mapActions('npasupplier', ['fetchSuppliers']),
      getDistinctValues: getDistinctValues,
      getOccurences: getOccurences,
      setSearch(searchValue) {
        this.selectedFilter = searchValue
        this.searchRequested = true
        this.fetchData(Object.assign({ purchaser: this.userRoles.includes('manager') ? '' : this.userInfos.uid, year: this.year }, searchValue))
      },
      async exportSupplierSettings() {
        let dataForExcel = []
        let headerData = []

        const today = new Date(Date.now()).toLocaleString().split(',')[0].replace(/\//g, '')
        const fileName = today + `_dataobs_recherche_fournisseurs`
        const allSettings = this.suppliers

        allSettings.forEach(row => {
          let clonedRow = {}
          clonedRow.annee = row.year
          clonedRow.rayon = row.rayon
          clonedRow.rayonLabel = row.rayonLabel
          clonedRow.paymentSupplierCode = row.paymentSupplierCode
          clonedRow.paymentSupplierLabel = row.paymentSupplierLabel
          clonedRow.paymentNegotiationGroupCode = row.paymentNegotiationGroupCode
          clonedRow.paymentNegotiationGroupLabel = row.paymentNegotiationGroupLabel
          clonedRow.purchaser = row.purchaser
          clonedRow.startDate = row?.startdate?.value ?? ''
          clonedRow.endDate = row?.enddate?.value ?? ''
          dataForExcel.push(Object.values(clonedRow))
          if (!headerData.length) headerData.push(clonedRow)
        })

        let reportData = {
          title: fileName,
          data: dataForExcel,
          headers: this.getLabelHeaders(Object.getOwnPropertyNames(headerData[0]))
        }

        exportXls(reportData)
      },
      redirectDetail(supp) {
        this.$router.push({
          name: 'vsupplierdetail',
          query: {
            id: supp.paymentSupplierCode,
            gdn: supp.paymentNegotiationGroupCode,
            rayon: supp.rayon
          }
        })
      },
      async fetchData(filters) {
        this.showLoader = true
        try {
          await this.fetchSuppliers(filters)
          this.showLoader = false
        } catch (err) {
          this.showLoader = false
        }
      },
      getLabelHeaders(headers) {
        return headers.map(header => this.$t(`export.${header}`))
      }
    },
    mounted() {
      if (this.year)
        this.selectedFilter = {
          supplier: this.cnuf,
          rayon: this.rayon,
          paymentLabel: this.gdn
        }
      this.fetchData({
        purchaser: this.userRoles.includes('manager') ? '' : this.userInfos.uid,
        year: this.year,
        ...this.selectedFilter
      })
    }
  }
</script>

<style scoped lang="scss">
  .body-container {
    display: flex;
    margin-top: 1em;
    width: 100%;
  }

  .body-menu {
    -webkit-box-flex: 1;
    flex: 1 1 100%;
    max-width: 20%;
    height: fit-content;
    padding: 3em 1em 10em 2em;
    box-sizing: border-box;
    border-right: 1px solid $lightgrey;

    .tags {
      margin-left: 2.9em;
    }
  }

  .body-content {
    flex-wrap: wrap;
    margin: 1em;
    width: 100%;
  }

  .body-cards {
    margin: 1em 0;
    &.arround {
      justify-content: space-between;
    }
  }

  .body-title {
    height: 36px;
    width: 100%;
    border-bottom: 1px solid #009688;
  }
  .scrollbar {
    height: calc(100vh - 200px);
    overflow-y: scroll;
  }
  ::-webkit-scrollbar {
    border-radius: 11px;
    width: 11px;
    background-color: lightgrey;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $maingreen;
  }

  .header {
    margin-top: 10px;
  }
</style>
