<template>
  <div class="button opacity" :class="[color ? `button-${color}` : '', disabled ? `button-disabled` : '']" @click="$emit('action')">
    <label class="text-bold">{{ label }}</label>
    <v-svg v-if="icon" class="icon" :name="icon" :size="iconSize" :color="iconColor" ></v-svg>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg'

export default {
  name: 'VButton',
  components: {
    VSvg
  },
  props: {
    /**
     * button's text
    */
    label: {
      type: String,
      default: ''
    },
    /**
     * button's icon
    */
    icon: {
      type: String,
      default: ''
    },
    /**
     * button's color
    */
    color: {
      type: String,
      default: ''
    },
    /**
     * button's icon color
    */
    iconColor: {
      type: String,
      default: ''
    },
    /**
     * button's icon color
    */
    iconSize: {
      type: String,
      default: '16px'
    },
    /**
     * is the btn disabled
    */
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/styles/_colors";

.button {
  cursor: pointer;
  border-radius: 8px;
  padding: 0.3em ;
  height: fit-content;
  width: fit-content;
  text-align: center;

  &-disabled {
    pointer-events: none;
    color: lightgrey !important;
  }

  &-pink {
    background-color: $pink;
    color: white;
    fill: white;
  }
  &-green {
    background-color: rgb(0,150,136);
    color: rgb(255,255,255);
  }
  &-transparent {
    &-pink {
      color: $pink;
      fill: $pink;
    }

    &-blue {
      color: $blue;
      fill: $blue;
    }

    &-darkblue {
      color: $darkblue;
      fill: $darkblue;
    }
  }

  .icon {
    background-color: $maingreen;
    border-radius: 50%;
    color: rgb(255,255,255);
    /*width: 4.4rem;*/
    height: 3rem!important;
    padding: 10px;
  }
}
.add .button .icon {
  width: 5rem;
  height: 5rem !important;
  padding: 15px;
}
</style>
