import { inject } from 'vue'
import http from '@/http'

const state = {
    users: []
  }

const  mutations = {
  setUsers(state, users) {
    state.users = users
  }
}

const actions = {
  async fetchUsers({ state, commit, rootState }, { filter, values }) {

    const searchParams = {
      filter,
      values
    }

    try {
      users = await http
      .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
      .get(`user/user`, { searchParams })
      .json()
    } catch (error) {
      console.error(error)
    }

    commit('setUsers', users)
  }
}

const getters = {
  users(state) {
    return state.users
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
