import { inject } from 'vue'
import http from '@/http'

const state = {
  token: '',
  userInfos: {},
  userRoles: [],
  year: ''
}

const mutations = {
  setToken(state, token) {
    state.token = token
  },
  setUserInfos(state, userInfos) {
    state.userInfos = userInfos
  },
  setUserRoles(state, userRoles) {
    state.userRoles = userRoles
  },
  setYear(state, year) {
    state.year = year
  }
}

const actions = {
  async fetchUser({ state, commit }) {
    let token = inject('token')
    let userInfos = inject('userInfos')
    let userRoles = inject('userRoles')

    commit('setUserInfos', userInfos)
    commit('setToken', token)
    commit('setUserRoles', userRoles)
  }
}

const getters = {
  token(state) {
    return state.token
  },
  userInfos(state) {
    return state.userInfos
  },
  userRoles(state) {
    return state.userRoles
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
