<template>
  <div class="autocomplete">
    <div class="label-container">
      <v-label
        v-for="(label, index) in labels"
        v-if="!isEnter"
        :key="index"
        class="autocomplete-label"
        :label="label.label"
        @action="removeLabel(index)"
      />
      <v-label
        v-for="(label, index) of search"
        :key="index"
        class="autocomplete-label"
        :class="index"
        :label="label"
        @action="removeAllLabel(index)"
      />
    </div>
    <div class="autocomplete-search">
      <input
        v-model="supplierSearch"
        class="search-input supplier"
        type="text"
        autocomplete="off"
        :placeholder="placeholder1"
        @click="reOpenResults"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter()"
        @focus.native="focused = true"
      />
      <input
        v-model="rayonSearch"
        class="ml-2 search-input rayon"
        type="text"
        autocomplete="off"
        :placeholder="placeholder2"
        @click="reOpenResults"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter()"
        @focus.native="focused = true"
      />
      <input
        v-model="searchByPaymentLabel"
        class="ml-2 search-input mr-2 paymentLabel"
        type="text"
        autocomplete="off"
        :placeholder="placeholder3"
        @click="reOpenResults"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter()"
        @focus.native="focused = true"
      />
      <v-button :icon="'search'" @click="onEnter()" />
    </div>
  </div>
</template>

<script>
  import VLabel from './Label'
  import VButton from '@/components/Button.vue'
  import { mapState } from 'vuex'

  export default {
    name: 'Autocomplete',
    components: { VLabel, VButton },
    props: {
      /**
       * Component width
       */
      width: {
        type: String,
        default: '1000'
      },
      /**
       * Input placeholder
       */
      placeholder1: {
        type: String,
        default: ''
      },
      placeholder2: {
        type: String,
        default: 'Recherche par rayon de paiement'
      },
      placeholder3: {
        type: String,
        default: 'Recherche par libellé de paiement'
      }
    },
    data() {
      return {
        isOpen: false,
        results: [],
        labels: [],
        search: {},
        supplierSearch: '',
        searchByPaymentLabel: '',
        rayonSearch: '',
        arrowCounter: 0,
        focused: false,
        isEnter: false,
        searchAll: ''
      }
    },
    computed: {
      ...mapState('searchFilters', ['cnuf', 'rayon', 'gdn'])
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
      if (this.cnuf) this.search.supplier = this.cnuf
      if (this.rayon) this.search.rayon = this.rayon
      if (this.gdn) this.search.paymentLabel = this.gdn
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside)
    },
    methods: {
      setResult(result, index) {
        if (!this.labels.find(s => s.el.id == result.el.id)) {
          this.labels.push(result)
        }
        this.search = []
        this.isOpen = false
        this.results = []
        this.$emit('set-search', this.labels, index)
      },
      removeLabel(index) {
        this.searchAll = ''
        this.labels.splice(index, 1)
        this.$emit('set-search', this.labels)
      },
      removeAllLabel(index) {
        if (index === 'rayon') {
          this.$store.commit('searchFilters/setRayon', '')
        }
        if (index === 'paymentLabel') {
          this.$store.commit('searchFilters/setGDN', '')
        }
        if (index === 'supplier') {
          this.$store.commit('searchFilters/setCnuf', '')
        }
        this.searchAll = ''
        delete this.search[index]
        this.$emit('set-search', this.search)
      },
      onArrowDown() {
        if (this.arrowCounter < this.results.length) {
          this.arrowCounter = this.arrowCounter + 1
        }
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1
        }
      },
      onEnter() {
        if (this.supplierSearch) {
          this.search.supplier = this.supplierSearch
          this.$store.commit('searchFilters/setCnuf', this.supplierSearch)
        }

        if (this.rayonSearch) {
          this.search.rayon = this.rayonSearch
          this.$store.commit('searchFilters/setRayon', this.rayonSearch)
        }

        if (this.searchByPaymentLabel) {
          this.search.paymentLabel = this.searchByPaymentLabel
          this.$store.commit('searchFilters/setGDN', this.searchByPaymentLabel)
        }

        this.supplierSearch = ''
        this.rayonSearch = ''
        this.searchByPaymentLabel = ''
        this.labels = []
        this.isEnter = true
        this.results.forEach(s => this.labels.push(s))
        this.isOpen = false
        this.arrowCounter = 0
        this.searchAll = this.search
        this.results = []
        this.$emit('set-search', this.search)
      },
      reOpenResults() {
        if (this.results.length) {
          this.isOpen = true
        }
      },
      handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
          this.isOpen = false
          this.focused = false
          this.arrowCounter = 0
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '~@/styles/_colors';

  .autocomplete {
    width: 100%;
    height: 37px;
    &-label {
      display: flex;
      align-items: center;
      color: $darkgrey;
      font-size: 14px;
      white-space: nowrap;
    }
  }

  .autocomplete-search {
    border-radius: 8px 8px 0px 0px;
    padding: 1em 0 0 0;
    margin: 0 1em;
    position: relative;
    display: flex;
    justify-content: space-between;

    input {
      background-color: $background;
      border: 0;
      color: $black;
      height: 30px;
      outline: none;
      width: 100%;
      font-size: 90%;
      border-bottom: 1px solid $lightgrey;
      &::placeholder {
        color: $mediumgrey !important;
      }
    }

    & input.supplier:focus {
      border-bottom: 2px solid rgb(0, 150, 136) !important;
    }
    & input.rayon:focus {
      border-bottom: 2px solid rgb(20, 178, 218) !important;
    }
    & input.paymentLabel:focus {
      border-bottom: 2px solid #f90 !important;
    }
  }

  .autocomplete-search:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 0px;
    bottom: 0;
    width: 20px;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    position: absolute;
    z-index: 1;
    background-color: white;
    overflow: auto;

    left: 19px;
    min-width: 400px;
    max-width: 1253px;
    top: 128px;
    bottom: auto;
    max-height: 250px;
    height: 250px;

    &-categories {
      text-align: left;
      padding: 1em 1em 0.5em 1em;
      font-weight: bold;
    }
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    cursor: pointer;

    font-size: 14px;
    overflow: hidden;
    padding: 0 15px;
    line-height: 48px;
    height: 48px;
    -webkit-transition: background 0.15s linear;
    transition: background 0.15s linear;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: $lightgrey;
  }

  .label-container {
    padding: 0 1em;
    display: flex;
    margin-top: -1em;
  }

  .right-inner-addon {
    position: relative;
  }
  .right-inner-addon input {
    padding-right: 30px;
  }
</style>
