<template>
  <div class="supplier-detail-card-container">
    <div class="card-header flex justify-space align-center">
      <span class="card-header-title">{{ $t('SUPPLIER_DETAIL.UNTIL') }} {{ formatDate(setting.enddate) }}</span><br>
    </div>

    <div class="card-body">
      <div class="flex">
        <tag
          :tag="setting.sendmode"
        />
        <tag
          v-for="(extraction, index) in formattedExtractionsTypes"
          :key="index"
          :tag="extraction"
        />
      </div>

      <div class="body-content flex wrap">
        <div class="body-line">
          <span class="text-teal text-bold">{{ $t('SUPPLIER_DETAIL.FREQUENCY.TITLE') }}</span>
        </div>
        <div class="body-line">
          <span>{{ $t(`SUPPLIER_DETAIL.FREQUENCY.${setting.frequency}`) }}</span>
        </div>
        <div v-if="setting.perimeter" class="body-line">
          <span class="text-teal text-bold">{{ $t('SUPPLIER_DETAIL.CONSOLIDATION.TITLE') }}</span>
        </div>
        <div v-if="setting.perimeter" class="body-line">
          <span>{{ $t(`SUPPLIER_DETAIL.CONSOLIDATION.${setting.perimeter}`) }}</span>
        </div>
        <div class="body-line">
          <span class="text-teal text-bold">{{ $t('SUPPLIER_DETAIL.AVERAGE_SALES_PRICE.TITLE') }}</span>
        </div>
        <div class="body-line">
          <span>{{ $t(`SUPPLIER_DETAIL.AVERAGE_SALES_PRICE.${setting.statusofthepurchasepriceaveragecolumn}`) }}</span>
        </div>
      </div>

      <div class=" mt-2">
        <span class="text-teal text-bold">{{ $t('SUPPLIER_DETAIL.MARKETS') }}</span>
        <div class="body-content-markets">
          <ul>
            <li v-for="(market, index) in filteredMarkets" :key="index">[{{ market.id }}] {{ market.label }}</li>
          </ul>
        </div>
      </div>

      <div v-if="setting.negoGroups">
        <span class="text-teal text-bold">{{ $t('SUPPLIER_DETAIL.NEGOGROUPS') }}</span>
        <div class="body-content-markets">
          <ul>
            <li v-for="(nego, index) in setting.negoGroups" :key="index">[{{ nego.sid }}] {{ nego.label }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg'
import Tag from '@/components/Tag'
import VButton from '@/components/Button'
import { formatDate } from '../utils/utils'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SupplierDetailCard',
  components: {
    VSvg,
    Tag,
    VButton
  },
  props: {
    setting: {
      type: Object,
      default: () => {}
    },
  }, 
  computed: {
    ...mapGetters('nomenclature', ['markets']),
    filteredMarkets() {
      const marketsList = this.setting.marketslist

      return marketsList.split(',').map(sMarket => {
        const m = this.markets.find(market => sMarket.toString() === market.id.toString())
        return {
          id: m ? m.id : sMarket,
          label: m ? m.label : 'Marché inconnu'
        }
      })
    },

    formattedExtractionsTypes() {
      return this.setting.typofextraction.split(',')
    }
  },
  data() {
    return {
      tags: [{ label: 'EDI', class: 'EDI' }, { label: 'S', class: 'S' }, { label: 'V', class: 'V' }],
    }
  },
  methods: {
    ...mapActions('nomenclature', ['fetchMarkets']),
    formatDate: formatDate,
    beforeEnter: function (el) {
      el.style.height = '0'
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave: function (el) {
      el.style.height = '0'
    },
    showCard() {
      this.show = !this.show
      this.$emit('showCard', this.show)
    }
  }
}
</script>

<style lang="scss">
.supplier-detail-card-container {
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.29);
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.29);
  background-color: white;
  border-radius: 3px;

  // padding: 2em 0.5em 2em 2em;
  margin: 0.5em;
  font-size: .9em;
  max-width: 32%;

  .card-title {
    margin-top: 2em;
  }

  .card-body {
    padding: 2em;
    width: 100%;
  }

  .card-header {
    padding: 1em 0.5em 1em 1em;
    border-bottom: 1px solid $lightgrey;
    .card-header-title {
      padding-top: 1.3em;
      padding-bottom: 1.3em;
    }
  }

  .body-line {
    width: 50%;
    padding-top: 2em;
  }
}

.body {
  &-content {
    &-markets {
      overflow: auto;
      min-height: 60px;
    }
  }
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul {
  position: relative;
  list-style-type: none;
  -webkit-padding-start: 20px;

}

li::before {
  content: '>';
  position: absolute;
  left: 0;
  color: $maingreen;
}
</style>
