<template>
  <div>
    <div v-if="dataTab.length" class="data-grid">
      <Loading :isLoading="isLoad"></Loading>
      <div class="table">
        <div class="thead">
          <div class="tr">
            <span v-for="(col, key) in columns"
              :class="[isHiddenColumns.includes(key) ? 'hide-td' : 'td']" :key="key"
              @click="sortBy(key)">
              {{ col }}
            </span>
          </div>
        </div>
        <div class="tbody">
          <div @click="$emit('action-row', key)"
            :class="['tr', key & 1 ? 'even' : 'odd', isClickable ? 'clickable' : '']" v-for="(row, key) in tab"
            :key="key">
            <span v-for="(column, name, key) in row" :key="name" v-html="getLabelWithoutCode(name, column)"
              :class="[isHiddenColumns.includes(key) ? 'hide-td' : name == this.orderName ? 'td bold' : 'td']" />

            <span class="td button-edit" v-if="!isRVF">
              <v-svg class="edit" name="pencil" @click="$emit('edit-element', row)" />
            </span>
            <button type="button" v-if="isExport" style="padding: 7px;border: none;background: none; cursor: pointer"
              v-on:click="downloadCSVData('DATAOBS_V2/PRT/' + row.e, row.e)">
              <v-svg class="edit" name="export" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="empty-grid flex mt-2 ml-2" v-else>
      {{ $t('SUPPLIER_SEARCH.NO_RESULTS') }}
    </div>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg.vue'
import { mapActions } from 'vuex'
import Loading from '@/components/Loading.vue'
import { saveAs } from 'file-saver'
import VButton from '@/components/Button.vue'
import VModal from '@/components/v2/Modal.vue'

export default {
  name: 'DataGrid',
  components: {
    VSvg,
    VButton,
    VModal,
    Loading
  },
  props: {
    /**
     * data tab
     */
    dataTab: {
      type: Array,
      default: () => []
    },
    /**
     * column tab
     */
    columns: {
      type: Array,
      default: () => { }
    },
    /**
     * message when no datas
     */
    noDatasLabel: {
      type: String,
      default: function () {
        // return this.$t('SUPPLIER_SEARCH.RESULTS')
        return ''
      }
    },
    /**
     * is the line clickable
     */
    isClickable: {
      type: Boolean,
      default: false
    },
    /**
     * add edit and delete
     */
    isEditable: {
      type: Boolean,
      default: false
    }
    ,
    /**
     * add edit and delete
     */
    isExport: {
      type: Boolean,
      default: false
    },
    isHiddenColumns: {
      type: Array,
      default: []
    },
    manager: {
      type: Object,
      default: {}
    },
    isRVF: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      assistants: '',
      tab: this.dataTab,
      prevKey: '',
      orderName: 'Nom',
      orderDesc: -1,
      columnFromKey: [
        { name: 'Rayon', value: 0 },
        { name: 'CnufCode', value: 1 },
        { name: 'Cnuf', value: 2 },
        { name: 'GDN', value: 3 },
        { name: 'Buyer', value: 4 },
        { name: 'AssistantPrincipal', value: 5 },
        { name: 'AssistantSecondaire', value: 6 },
        { name: 'Email', value: 7 },
      ],
      isLoad: this.isLoading
    }
  },
  computed: {
    renderedHTML() {
      return (code,index) => {
        const rawHTML = `
          <div>
            <p>${code}</p>
          </div>
        `
        return rawHTML
      }
    },
  },
  methods: {
    ...mapActions('supplier', ['fetchRVFSales', 'downloadFileRVF']),
    sortBy(key) {
      key = this.columnFromKey.find(c => c.value === key).name
      this.orderName = key
      if (this.prevKey === key) {
        let vue = this
        this.tab.sort(function compare(a, b) {
          if (a[key] < b[key]) return -1 * vue.orderDesc
          if (a[key] > b[key]) return 1 * vue.orderDesc
          return 0
        })
        this.orderDesc = this.orderDesc * -1
      } else {
        this.tab.sort(function compare(a, b) {
          if (a[key] < b[key]) return -1
          if (a[key] > b[key]) return 1
          return 0
        })
        this.prevKey = key
        this.orderDesc = this.orderDesc * -1
      }
    },
    isManager: function(row) {
      //return (row.AssistantPrincipal?.includes(this.manager.uid) || row.AssistantSecondaire?.includes(this.manager.uid) || row.Buyer?.includes(this.manager.uid) || this.manager.roles?.includes("manager")) ? true : false;
      return true
    },
    async downloadCSVData(file, nameFile) {
      let csvdata = await this.downloadFileRVF({ nameFile: file })
      saveAs(csvdata.file[0], nameFile)
    },
    getLabelWithoutCode(name, str = '') {
      let i = 1
      if(["Buyer"].includes(name)) {
        const index = str ? String(str).indexOf('-') : -1
        return String(str).substring(index + 1).trim()
      }else if (name === 'AssistantPrincipal') {
        return this.renderedHTML(String(str).toUpperCase().replace(/;/g,'<br/>'))
      }else if (name === 'Email') {
        return this.renderedHTML(String(str).replace(/,/g,'<br/>'))
      }else {
        return String(str).toUpperCase()
      }
    }
  },
  mounted() {
    this.tab = this.dataTab
    this.isLoad = this.isLoading

  },
  watch: {
    dataTab(val) {
      this.tab = val
      //this.sortBy(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.odd {
  background-color: white;
}

.even {
  background-color: $background;
}

.bold {
  font-weight: 700;
}

.data-grid {
  /*margin: 10px auto;*/
  /*padding: 2px;*/
  overflow: auto;
  height: 406px;
}

.table {
  background-color: rgb(0, 150, 136);
  display: table;
  width: 100%;
  font-size: 0.7em;
  color: black;

}

.thead {
  display: table-header-group;
  position: -webkit-sticky;
  position: sticky;
  top: -2px;
  z-index: 2;
  background-color: $primary;
}

.tbody {
  display: table-row-group;
}

.tr {
  display: table-row;
  border-radius: 8px;
  height: 48px;
}

.td {
  display: table-cell;
  position: relative;
  padding: 4px 16px;
  vertical-align: middle;

}

.td-right {
  text-align: right;
}

.thead .td {
  font-weight: bold;
  cursor: pointer;
  color: white;
}

.empty-grid {}

.clickable:hover {
  opacity: 0.5;
  cursor: pointer;
  background-color: #c5cae9;
}

.pencil {
  /* color: $blue;*/
  padding-right: 3.5rem;
  color: white;
  top: 11px;
  position: absolute;
}

.trash {
  color: $pink;

}

.edit {
  background-color: $maingreen;
  border-radius: 50%;
  color: rgb(255, 255, 255);
  /*width: 4.4rem;*/
  height: 3.6rem !important;
  padding: 10px;
}

.export-RVF {
  background-color: $maingreen;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  width: 4.4rem;
  height: 3.6rem !important;
  padding: 10px;

}

.button-edit {
  &:hover {
    cursor: pointer;
  }
}

.hide-td {
  display: none;
}

.button-grid {
  background-color: rgb(0, 150, 136);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  font-size: 11px
}
</style>
