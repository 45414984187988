<template>
  <div class="main-container">
    <Loader :is-loading="isLoading" />
    <div class="text-teal flex justify-space">
      <br>
    </div>

    <div class="search">
       <div class="content"><span class="label-date-debut">Date debut</span>  <input type="date" class="date-debut" v-model="fromDate"/> </div>
       <div class="content"><span class="label-date-fin">  Date fin    </span> <input type="date" class="date-fin" v-model="toDate" /></div>
       <div> <v-button  :icon="'search'" color="transparent-blue" @action="searchRVF()" class="button-search"  /></div>
<!--      <button type="button" class="btn btn-info action_btn" v-on:click="downloadCSVData">
        Download
      </button>-->
    </div>
    <div>
      <data-grid :columns="columns" :data-tab="formattedDatas" :isClickable="false" :isExport="true" :isRVF="true" :isLoading="false"/>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import DataGrid from '@/components/DataGrid.vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import VSearch from '@/components/Search.vue'
import VButton from '@/components/Button.vue'
import Loader from './v2/Loader'

export default {
  name: 'Infos',
  components: {
    DataGrid,
    VSearch,
    VButton,
    Loader
  },
  data() {

    return {
      isLoading: false,
      search: [],
      isActivated: false,
      loading: false,
      loadingAlerts: false,
      nextItem: 1,
      items: [],
      displayed: 10, // Number of cards to displayed in oneShot
      step: 0,
      page: 1,
      fromDate: null,
      toDate: null,
      infosSupplier: []}},
  computed: {
    ...mapGetters('supplier', ['rvfSales']),
    formattedDatas() {
      return this.rvfSales.map(e => {
        return { e
        }
      })
    },
    columns() {
      return this.formattedDatas.length > 0
        ? Object.keys(this.formattedDatas[0]).map(e => {
          return this.$t('RVF.FILE')
        })
        : []
    },
    totalPages() {
      return Math.ceil(this.total )
    },
    allPages() {
      return (1 )
    }

  },
  methods: {
    ...mapActions('supplier', ['fetchRVFSales']),
    async loadRVFSales(){
      this.isLoading = true
      await this.fetchRVFSales({fromDate: this.fromDate, toDate: this.toDate})
      this.isLoading = false
    },
    searchRVF() {
      this.loadRVFSales()
    }

  }
}
</script>
<style scoped lang="scss">
@import '~@/styles/_colors';

.main-container {
  padding-bottom: 20rem;
}

.card {
  min-width: 400px;
  position: relative;
  margin: 0 1rem;

  > div {
    flex: 0 0 auto;
  }

  &-header {
    width: 100%;
    color: $pink;

    > h3 {
      font-size: 23px;
      line-height: 27px;
    }

    > span {
      font-size: 13px;
      line-height: 15px;
    }

  }
  &-body {
    width: 100%;

    > span {
      font-size: 14px;
      line-height: 17px;
    }
  }
  &-footer {
    height: 50px;
    position: absolute;
    bottom: -3rem;
    right: 2rem;
  }
}

.alerts {
  &-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden; /* Hide scrollbars */
    overflow-x: scroll;
    padding-bottom: 5rem;
  }
}

.loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search  {
  border-bottom: 1px solid #009688;
  padding: 49px;
  display: flex;
  align-items: center;
  .content{
    display: flex;
    align-items: center;
    margin-right: 40px;
  }
}
.add-supp {
  position: absolute;
  top: -67px;
  right: 0px;
}

.date-debut  {
  font-size: 15px;
}

.date-fin  {
  font-size: 15px;
}
.label-date-fin  {
  font-size: 22px;
  margin-right: 20px;
}

.label-date-debut  {
  font-size: 22px;
  margin-right: 20px;
}
</style>
