<template>
  <div class="modal-container" v-if="showModal">
    <div class="overlay"></div>
    <div class="modal ">

      <div class="flex justify-space">
        <h2 class="text-darkblue">{{ $t(`MODAL.EDIT_SUPPLIER`) }}</h2>

        <button class="close-button" @click="closeModal()"> <v-svg name="cross" style="height: 16px;" /> </button>
      </div>

      <div class="add-suppl">
        <div class="">
          <label class="text-field-label text-bold">{{ $t(`INFOS.Cnuf`) }}*</label>
          <input type="text" readonly v-model="cnuf" class="edit-suplp mb-1 w-100" />
          <v-chip v-if="this.err.cnuf" :label="this.err.cnuf" :icon="'warning'" :color="'error'" :is-small="true" />
        </div>

        <div class="mt-1">
          <label class="text-field-label text-bold"> {{ $t(`INFOS.GDN`) }}*</label>
          <input type="text" readonly v-model="gdnPaiement" class="edit-suplp mb-1 w-100" />
          <v-chip v-if="this.err.gdn" :label="this.err.gdn" :icon="'warning'" :color="'error'" :is-small="true" />
        </div>

        <div class="mt-1">
          <label class="text-field-label text-bold"> {{ $t(`INFOS.Email`) }}*</label>
          <div class="flex email-container">
            <input type="email" v-model="inputEmail" :placeholder="$t(`INFOS.EmailPlaceholder`)"
              class="edit-suplp w-100" @keydown.enter="onEnter()" />
            <button class="addEmailBtn" color="green" @click="onEnter()">
              Ajouter
            </button>
          </div>
        </div>
        <v-label class="email-item mt-0" :class="index" v-for="(email, index) in supplierInfosEmail" :key="index"
          :label="email" @action="removeEmail(index)" />
      </div>
      <div class="flex w-100 justify-end mb-2 pr-2">
        <v-button :label="$t(`INFOS.Enregistrer`)" class="saveBtn" color="green" :disabled="!supplierInfosEmail.length"
          @action="editSupplier" />
      </div>
    </div>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg'
import VInputModal from '@/components/InputModal'
import ElemPicker from '@/components/ElemPicker.vue'
import VButton from '@/components/Button.vue'
import AutocompleteEmail from '@/components/AutocompleteEmail'
import {mapActions, mapState} from 'vuex'
import VTextField from '@/components/TextField'
import VChip from '@/components/Chip'
import VLabel from './Label'
import validator from 'validator'

export default {
  name: 'SForm',
  components: {
    VSvg,
    VInputModal,
    AutocompleteEmail,
    ElemPicker,
    VButton,
    VTextField,
    VChip,
    VLabel
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    supplierInfos: {
      type: Object,
      default: () => { }
    }
    ,
    named: {
      type: String,
      default: ''
    },
    mandatAssistantePrincipale: {
      type: Object,
      default: () => { }
    },
    mandatAssistanteSecondaire: {
      type: Object,
      default: () => { }
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    manager: {
      type: Object,
      default: {}
    }
  },
  computed: {
    ...mapState('global', ['year']),

    error() {
      if (!this.validEmail(this.supplierInfosEmail)) {
        this.err.email = 'Email invalide'
      } else {
        this.err.email = ''
      }

      return this.err.email === '' ? false : true

    }
  },
  data() {
    return {
      supplierInfosEmail: this.supplierInfos.Email ?? [],
      cnuf: this.supplierInfos ? this.supplierInfos.Cnuf : '',
      supplierInfosManager: this.manager.uid + '-' + this.manager.displayName,
      supplierInfosId: this.supplierInfos ? this.supplierInfos.Fournisseur : '',
      gdnPaiement: this.supplierInfos ? this.supplierInfos.GDN : '',
      loading: false,
      validationError: '',
      inputEmail: '',
      err: {
        gdn: '',
        cnuf: '',
        email: '',
        manager: '',
        button: '',
      }


    }
  },
  methods: {
    ...mapActions('npasupplier', ['updateSuppliersInfos', 'fetchUser', 'fetchSuppliersInfosByManager', 'addSuppliersInfos']),
    async editSupplier() {
      try {
        await this.updateSuppliersInfos({
          email: this.supplierInfosEmail.join(', '),
          cnuf: this.supplierInfos.CnufCode,
          gdn: this.supplierInfos.GDN.substring(0, this.supplierInfos.GDN.indexOf(" -")),
          rayon: this.supplierInfos.Rayon,
          year: this.year
        })
        this.$toast.success("Les emails ont bien été modifiés !")
        this.closeModal()
      } catch (error) {
        this.$toast.error("Il s'est produite une erreur, veuillez réessayer svp")
        this.closeModal()
      }
    },
    closeModal() {
      this.tempMandatBuyer = null
      this.tempMandatAssistantePrincipale = null
      this.tempPandatAssistanteSecondaire = null
      this.$emit('close-modal')
    },
    validEmail(email) {
      let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
      return re.test(email)
    },
    removeEmail(index) {
      this.supplierInfosEmail.splice(index, 1)
    },
    updateValue: function () {
      this.validationError = ''
      if (this.type === 'email') {
        this.validationError = 'Email invalide'
      }
    },
    onEnter() {
      if (!this.inputEmail)
        return;
        // this.validEmail(this.inputEmail)
        
      let convertMail = this.inputEmail.toLowerCase()
      if (validator.isEmail(convertMail) && !this.supplierInfosEmail.includes(convertMail)) {
        this.supplierInfosEmail.push(convertMail)
        this.inputEmail = ''
      } else if (this.supplierInfosEmail.includes(convertMail))
        this.$toast.error('Cet email a déjà été saisie')
      else
        this.$toast.error('Email invalide')
    },
  }
}
</script>
<style scoped lang="scss">
@import "~@/styles/_colors";

.autocomplete {
  width: 100%;
}

.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  background: white;
  color: #333;
  position: fixed;
  top: 22%;
  width: 47rem;
  padding: 0rem 0rem;

}

.icon {
  &:hover {
    cursor: pointer;
  }
}

.addEmailBtn {
  background-color: $primary;
  border: none;
  margin-left: 10px;
  margin-left: 10px;
  color: #fff;
  padding: 0 10px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }
}

.justify-space {
  justify-content: space-between;
  background-color: rgb(0, 150, 136);
  color: rgb(255, 255, 255);
  font-size: small;
  padding: 0 16px;
}

.add-suppl {
  padding: 25px;
}

.edit-suplp {
  display: block;
  font-size: 16px;
  margin-top: 0;
  background: 0 0;
  border-width: 0 0 1px;
  line-height: 26px;
  height: 30px;
  border-radius: 0;
  border-style: solid;
  width: 93%;
  box-sizing: border-box;
  padding: 2px 2px 1px;

  &:focus-visible {
    outline: none;
  }
}

.w-100 {
  width: 100%;
}

.saveBtn {
  padding: 7px 13px;
}

.email-item {
  background-color: #9f9e9e;
  color: #fff;
  font-size: 14px;
  padding: 3px 5px;
  border-radius: 20px;
  margin-right: 5px;
  font-weight: bold;
  display: inline-block;
}

.email-container {
  margin-bottom: 6px;
}

.close-button {
  background-color: #009688;
  color: white;
  border: none;
}
</style>
