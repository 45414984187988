<template>
  <div class="text-field">
    <div class="flex-justify-space">
      <div class="mr-1">
        <label for="inputField" class="text-field-label text-bold">{{ label }}<span v-if="error.length" class="error">*</span></label>
      </div>
      <div>
        <v-chip v-if="error" :label="error" :icon="'warning'" :color="'error'" :is-small="true" />
      </div>
    </div>
    <div>
      <input
        :value="getValue"
        :type="type"
        :name="type"
        autocomplete="off"
        class="text-field-input"
        :class="error.length ? 'error' : ''"
        :disabled="disabled"
        :placeholder="placeholder"
        v-on:input="$emit('input', $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
import VChip from '@/components/Chip'

export default {
  name: 'VTextField',
  components: {
    VChip
  },
  props: {
    /**
     * Input's label
     */
    label: {
      type: String,
      default: 'email'
    },
    /**
     * Input's type
     */
    type: {
      type: String,
      default: 'text'
    },
    /**
     * Input's placeholder
     */
    placeholder: {
      type: String,
      default: ''
    },
    /**
     * Error after submit
     */
    submitError: {
      type: String,
      default: ''
    },
    /**
     * input value
     */
    value: {
      type: String
    },
    /**
     * disabled input yes or no
     */
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type:Boolean,
      default: true
    },
  },
  data() {
    return {
      validationError: ''
    }
  },
  computed: {
    error() {
      return this.validationError || this.submitError
    },
    getValue(){
      this.updateValue(this.value)
      return this.value
    }
  },

  methods: {
    validEmail(email) {
      let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
      return re.test(email)
    },
    validPhone(phone) {
      let re = /^((\+|00)\d{1,3}|0)[1-9](\d{2}){4}$/
      return re.test(phone)
    },
    updateValue: function (value) {
      this.validationError = ''
      value = (value === null || value === undefined) ? '' : value
      if (!value && this.required) {
        this.validationError = this.$t('error.missing')
      } else if (this.type === 'email' && !this.validEmail(value)) {
        this.validationError = this.$t('error.email.invalid')
      } else if (this.type === 'tel' && (value !== '' && !this.validPhone(value))) {
        this.validationError = this.$t('error.tel.invalid')
      } else if (this.type === 'telExt' && (value !== ''  && !this.validPhone(value))) {
        this.validationError = this.$t('error.tel.invalid')
      }
      this.$emit('input', value)
      this.$emit('is-correct', !this.validationError.length)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/styles/_colors';

.text-field {
  width: fit-content;
  min-width: 200px;

  .flex-justify-space {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-input {
    height: 40px;
    width: -webkit-fill-available;
    font-size: 16px;
    border: 0;
    outline-width: 0;
    bottom: 0;
    color: $blue;
    background-color: transparent;

    &:disabled {
      color: $darkblue;
    }

    &::placeholder {
      color: $blue;
    }

    &:focus {
      border-bottom: 1px solid $blue;
      -webkit-transition: border 200ms ease-out;
      -moz-transition: border 200ms ease-out;
      -o-transition: border 200ms ease-out;
      transition: border 200ms ease-out;

      &::placeholder {
        color: transparent;
      }
    }

    &:not(:focus) {
      border-bottom: 1px solid $lightblue;
      -webkit-transition: border 200ms ease-in;
      -moz-transition: border 200ms ease-in;
      -o-transition: border 200ms ease-in;
      transition: border 200ms ease-in;
    }

    &.error {
      border-bottom: 1px solid $error;
      color: $blue !important;

      &::placeholder {
        color: $error;
      }

      &:focus::placeholder {
        color: transparent;
      }
    }
  }

  .error {
    color: $error;
  }

  &-label {
    color: $darkblue;
    font-size: 16px;
  }
}

.text-field-input:disabled{
  background-color: $lighterblue;
  padding-left: 5px;
  border-radius: 10px;
}


</style>
