import http from '@/http'
import { inject } from 'vue'
import { handleNetworkError } from '../../utils/utils'

const state = {
  suppliers: [],
  suppliersInfos: [],
  rvfSales: [],
  suppliersInfosByManager: [],
  negoGroups: [],
  settings: [],
  NPAsettings: [],
  ifls: []
}

const mutations = {
  setSuppliers(state, suppliers) {
    state.suppliers = suppliers
  },
  setNegoGroups(state, negoGroups) {
    state.negoGroups = negoGroups
  },
  setSuppliersInfos(state, data) {
    state.suppliersInfos = data
  },
  setRvfSales(state, data) {
    state.rvfSales = data
  },
  setSuppliersInfosByManager(state, data) {
    state.suppliersInfosByManager = data
  },
  setSupplierSettings(state, data) {
    state.settings = data
  },
  setNPASupplierSettings(state, data) {
    state.NPAsettings = data
  },
  addSupplierSetting(state, setting) {
    state.settings.push(setting)
  },
  updateSupplierSetting(state, setting) {
    const index = state.settings.findIndex(s => s.extractionid === setting.extractionid)
    state.settings.splice(index, 1, setting)
  },
  deleteSupplierSetting(state, extractionid) {
    const index = state.settings.findIndex(s => s.extractionid === extractionid)
    state.settings.splice(index, 1)
  }
}

const actions = {
  async fetchSuppliers({ state, commit, rootState }) {
    let suppliers
    const searchParams = {}

    try {
      suppliers = await http
        .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
        .get(`supplier/getSuppliers`, { searchParams })
        .json()
    } catch (error) {}

    commit('setSuppliers', suppliers)
  },

  async fetchSuppliersInfos({ state, commit, rootState }, params) {
    let data

    const searchParams = {
      search: params?.search?.toUpperCase()
    }
    return new Promise(async (resolve, reject) => {
      try {
        data = await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .get(`supplier/infos/search`, { searchParams })
          .json()
        commit('setSuppliersInfos', data)
        resolve()
      } catch (error) {
        handleNetworkError(error)
      }
    })
  },

  async fetchAllSuppliersInfos({ state, commit, rootState }) {
    let data
    return new Promise(async (resolve, reject) => {
      try {
        data = await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .get(`supplier/infos`)
          .json()
        commit('setSuppliersInfos', data)
        resolve()
      } catch (error) {
        handleNetworkError(error)
      }
    })
  },
  async updateSuppliersInfos({ state, commit, rootState }, params) {
    let data
    const searchParams = {
      email: params.email,
      ifls: params.ifls,
      name: params.name,
      /*      profile:params.profile,
      password:params.password,*/
      id: params.id,
      managerProfile: params.managerProfile
    }
    return new Promise(async (resolve, reject) => {
      try {
        await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .post(`supplier/saveSupplierInfos`, { searchParams })
          .json()
        resolve()
      } catch (error) {
        handleNetworkError(error)
      }
    })
  },
  async addSuppliersInfos({ state, commit, rootState }, params) {
    const searchParams = {
      email: params.email,
      ifls: params.ifls,
      name: params.name,
      profile: params.profile,
      password: params.password,
      id: params.id,
      managerProfile: params.managerProfile
    }
    return new Promise(async (resolve, reject) => {
      try {
        await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .post(`supplier/addSupplierInfos`, { searchParams })
          .json()
        resolve()
      } catch (error) {
        handleNetworkError(error)
      }
    })
  },

  async fetchSuppliersInfosByManager({ state, commit, rootState }, params) {
    let data

    const searchParams = {
      managerProfile: rootState.global.userInfos.uid
    }
    return new Promise(async (resolve, reject) => {
      try {
        data = await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .get(`supplier/infosByManager`, { searchParams })
          .json()
        commit('setSuppliersInfosByManager', data)
        resolve()
      } catch (error) {
        handleNetworkError(error)
      }
    })
  },

  async fetchNegoGroup({ state, commit, rootState }, params) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .get(`negoGroup/market/${params.markets}/sector/${params.sector}/orderSupplierCode/${params.supplierCode}`)
          .json()
        commit('setNegoGroups', data)
        resolve(data)
      } catch (error) {
        console.log('ERROR', error)
        reject(error)
        throw error

        handleNetworkError(error)
      }
    })
  },

  async fetchRVFSales({ state, commit, rootState }, params) {
    let data = []
    const searchParams = {
      fromDate: params.fromDate,
      toDate: params.toDate
    }
    try {
      if (rootState.global.userInfos !== undefined) {
        if (rootState.global.userInfos.email) {
          //if user is a supplier
          let dataSupplier = await http
            .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
            .get(`supplier/infosByEmail?email=${rootState.global.userInfos.email}`)
            .json()
          state.ifls = dataSupplier.map(user => user.ifls).toString()
        } else {
          let dataManager = await http
            .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
            .get(`supplier/infosByManager?managerProfile=${rootState.global.userInfos.uid}`)
            .json()
          state.ifls = dataManager.map(user => user.ifls).toString()
        }
      }
      data = await http
        .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
        .get(`supplier/sales`, { searchParams })
        .json()
      state.ifls.length === 0 ? (data = []) : (data = data.filter(d => state.ifls.includes(d.substring(0, 5))))
    } catch (error) {
      handleNetworkError(error)
    }

    commit('setRvfSales', data)
  },

  async downloadFileRVF({ state, commit, rootState }, params) {
    let data
    const searchParams = {
      nameFile: params.nameFile
    }
    try {
      data = await http
        .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
        .get(`supplier/downloadFile`, { searchParams })
        .json()
      return data
    } catch (error) {
      handleNetworkError(error)
    }
  },

  async fetchSuppliersSettings({ state, commit, rootState }, supplierCode) {
    let data
    const searchParams = {}
    try {
      data = await http
        .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
        .get(`supplier/${supplierCode}/settings`, { searchParams })
        .json()
    } catch (error) {
      console.log('Erreur', error)
    }

    await data.map(async (setting, index) => {
      if (setting.negogroupsids) {
        try {
          let negogroups = await http
            .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
            .get(`negoGroup/${setting.negogroupsids}`, { searchParams })
            .json()
          setting.negoGroups = negogroups ?? []
          return setting
        } catch (err) {
          throw err
        }
      }
    })

    commit('setSupplierSettings', data)
  },
  async addSupplierSetting({ state, commit, rootState }, searchParams) {
    searchParams.createdBy = rootState.global.userInfos.uid

    return new Promise(async (resolve, reject) => {
      try {
        const newSetting = await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .post(`supplier/supplierSetting`, { searchParams })
          .json()
        resolve()
        commit('addSupplierSetting', newSetting)
      } catch (error) {
        reject(error)
      }
    })
  },
  async updateSupplierSetting({ state, commit, rootState }, searchParams) {
    searchParams.updatedBy = rootState.global.userInfos.uid

    return new Promise(async (resolve, reject) => {
      try {
        const updatedSetting = await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .put(`supplier/supplierSetting`, { searchParams })
          .json()
        resolve()
        commit('updateSupplierSetting', updatedSetting)
      } catch (error) {
        reject(error)
      }
    })
  },
  async deleteSupplierSetting({ state, commit, rootState }, searchParams) {
    return new Promise(async (resolve, reject) => {
      try {
        await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .delete(`supplier/supplierSetting`, { searchParams })
          .json()
        resolve()
        commit('deleteSupplierSetting', searchParams.extractionid)
      } catch (error) {
        reject(error)
      }
    })
  },
  async auth(params) {
    /*    const searchParams = {
      managerProfile:rootState.global.userInfos.uid
    }
    return new Promise (async (resolve, reject) => {
      try {

        data = await http
          .extend({ headers: { Authorization: `Bearer ${rootState.global.token}` } })
          .get(`supplier/infosByManager`, { searchParams })
          .json()
        commit('setSuppliersInfosByManager', data)
        resolve()
      } catch (error) {
        /!* sendErrorToast('Erreur lors de la récupération des paramètres')*!/
      }
    })*/
  }
}

const getters = {
  suppliers(state) {
    return state.suppliers
  },
  negoGroups(state) {
    return state.negoGroups
  },
  suppliersInfos(state) {
    return state.suppliersInfos
  },
  suppliersSetting(state) {
    return state.suppliersSetting
  },
  suppliersInfosBYManager(state) {
    return state.suppliersInfosByManager
  },
  negoGroups(state) {
    return state.negoGroup
  },
  rvfSales(state) {
    return state.rvfSales
  },
  settings(state) {
    return state.settings
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

export function authorizationRVF(firebaseUser) {
  let data
  const searchParams = {
    email: firebaseUser.email
  }
  return new Promise(async (resolve, reject) => {
    try {
      data = await http
        .extend({ headers: { Authorization: `Bearer ${firebaseUser.accessToken}` } })
        .get(`supplier/authorizationRVF`, { searchParams })
        .json()
      state.ifls = data.map(user => user.ifls).toString()
      resolve(data)
    } catch (error) {
      handleNetworkError(error)
    }
  })
}
