<template>
  <div class="card-container" :class="{ 'active': isActive }">
    <div class="card-header">
      <div class="card-header-top"><span class="cnuf">{{supplier.paymentSupplierCode}}</span> <span class="campaignPeriode ml-1"> {{ $t("FROM") }} <strong>{{ getDateFomatDDMMYYYY(this.supplier?.startdate.value) }}</strong>  <span class="lowercase">{{ $t("TO") }}</span> <strong>{{ getDateFomatDDMMYYYY(this.supplier?.enddate.value) }}</strong> </span></div>
      <br>
      <div class="truncate">
        <span class="text-bold text-big">{{ supplier.paymentSupplierLabel }}</span>
      </div>
    </div>

    <div class="card-body">
      <div class="card-title">
        <div class="flex flex-wrap mb-2" >
           <strong>Rayon: </strong> <span class="ml-2">{{ `${supplier.rayon} - ${supplier?.rayonLabel ?? ''}` }} </span>
        </div>
        <div class="flex flex-wrap mb-2" >
           <strong>Acheteur: </strong> <span class="ml-2">{{ supplier.purchaser }}</span>
        </div>
        <div class="flex flex-wrap"  >
           <strong>GDN de paiement: </strong> <span class="ml-2">{{ supplier.paymentNegotiationGroupCode }} - {{ supplier?.paymentNegotiationGroupLabel }}</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import VSvg from '@/components/base/Svg'
import Tag from '@/components/Tag'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'SupplierCard',
  components: {
    VSvg,
    Tag
  },
  props: {
    supplier: {
      type: Object,
      default: null
    },
    typesOfSendModes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isActive(){
      return moment().isBefore(moment(this.supplier?.enddate.value))
    }
  },
  methods: {
    getDateFomatDDMMYYYY(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    beforeEnter: function (el) {
      el.style.height = '0'
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave: function (el) {
      el.style.height = '0'
    },
    showCard() {
      this.show = !this.show
      this.$emit('showCard', this.show)
    },
    getMarketLabel(id) {
      const m = this.markets.find(m => m.sid === id)
      return m ? m.label : this.$t('SUPPLIER_SEARCH.UNKNOWN_MARKET')
    }
  }
}
</script>

<style lang="scss">
.card-container {
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.29);
  box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
  border-top: 3px solid $jaffa;
  &.active {
    border-top: 3px solid $primary;
  }
  background-color: white;
  border-radius: 0 0 15px 15px;
/*  padding: 2em;
  margin: 1em 0.5em;*/
  font-size: .7em;
  width: 31%;
  cursor: pointer;
  max-height: 300px;
  .card-title {
    margin-top: 2em;
  }

  .card-header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lowercase {
  display: inline-block;
  text-transform: lowercase;
}

.orange .icon{
  color: $jaffa;
}
.orange .text-teal{
  color: $jaffa;
}

span.cnuf {
  border: 1px solid $jaffa;
  padding: 5px 15px 3px;
  border-radius: 30px;
  color: #777;
}
.active span.cnuf {
  border: 1px solid $primary;
}

.card-container .campaignPeriode strong {
  color: $jaffa;
}
.card-container.active .campaignPeriode strong {
  color: $primary;
}

</style>
