<template>
  <div class="main-container">
    <Loader :is-loading="showLoader" />
    <div class="flex mt-2">
      <div class="search">
        <input v-model="supplierSearch" type="text" class="search-suppliers" :placeholder="$t('INFOS.CNUFOrLibelle')" @keydown.enter="setSearch()" />
        <input v-model="rayonSearch" type="text" class="search-rayon" :placeholder="$t('INFOS.RayonPlaceholder')" @keydown.enter="setSearch()" />
        <input v-model="gdnSearch" type="text" class="search-rayon" :placeholder="$t('RVF.GDNPlaceholder')" @keydown.enter="setSearch()" />
      </div>
      <v-button :icon="'search'" @click="setSearch()" />
    </div>
    <div v-if="formattedDatas.length" class="results mt-2">
      {{ formattedDatas.length }} {{ $t('SUPPLIER_SEARCH.RESULTS') }}
      <v-button :icon="'export'" color="transparent-blue" @action="exportSupplierInfos()" />
    </div>
    <data-grid
      :columns="columns"
      :data-tab="formattedDatas"
      :is-clickable="false"
      :is-editable="true"
      :is-hidden-columns="hiddenColumns"
      @edit-element="openEditModal"
    />

    <SForm
      v-if="showModal"
      :show-modal="showModal"
      :edit-mode="editMode"
      :supplier-infos="selectedElement"
      :named="selectedElement.name"
      :manager="getUserInfos"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
  // @ is an alias to /src
  import DataGrid from '@/components/v2/DataGrid.vue'
  import { mapGetters, mapActions, mapState } from 'vuex'
  import VSearch from '@/components/Search.vue'
  import VButton from '@/components/Button.vue'
  import VToogleBtn from '@/components/ToogleBtn.vue'
  import VModal from '@/components/Modal.vue'
  import SForm from '@/components/v2/SupplierForm.vue'
  import { exportXlsSuppliersInfos } from '@/utils/xls'
  import Loader from './Loader'

  export default {
    name: 'Infos',
    components: {
      DataGrid,
      VSearch,
      Loader,
      VButton,
      VToogleBtn,
      VModal,
      SForm
    },
    data() {
      return {
        supplierSearch: '',
        rayonSearch: '',
        paymentLabelSearch: '',
        isActivated: false,
        showLoader: false,
        loadingAlerts: false,
        nextItem: 1,
        items: [],
        displayed: 10, // Number of cards to displayed in oneShot
        step: 0,
        page: 1,
        infosSupplier: [],
        showModal: false,
        selectedElement: {},
        editMode: true,
        hiddenColumns: [1],
        gdnSearch: ''
      }
    },

    computed: {
      ...mapGetters('npasupplier', ['suppliersInfos', 'rvfSales']),
      ...mapGetters('global', ['userInfos', 'userRoles']),
      ...mapState('global', ['year']),
      ...mapState('searchFilters', ['cnuf', 'rayon', 'gdn']),

      formattedDatas() {
        return this.suppliersInfos.map(e => {
          return {
            Rayon: `${e.rayon}-${e?.rayonLabel}`,
            CnufCode: e.paymentSupplierCode,
            Cnuf: `${e.paymentSupplierCode} - ${e.paymentSupplierLabel}`,
            GDN: `${e.paymentNegotiationGroupCode} - ${e.paymentNegotiationGroupLabel}`,
            Buyer: e.purchaser ?? '',
            AssistantPrincipal: e.purchaserAssistants ?? '',
            Email: e.email?.split(',') ?? []
          }
        })
      },
      columns() {
        return this.formattedDatas.length
          ? [
              ...Object.keys(this.formattedDatas[0]).map(e => {
                return this.$t(`INFOS.${e}`)
              }),
              this.$t('INFOS.Edit')
            ]
          : []
      },
      totalPages() {
        return Math.ceil(this.total)
      },
      getUserInfos() {
        return { user: this.userInfos, roles: this.userRoles }
      },
      isDisabled() {
        return this.supplierSearch.length > 0 || this.isActivated ? false : true
      }
    },
    watch: {
      year() {
        this.fetchDatas()
      },
      supplierSearch(newValue, old) {
        if (newValue !== old) {
          this.$store.commit('searchFilters/setCnuf', this.supplierSearch)
        }
      },
      rayonSearch(newValue, old) {
        if (newValue !== old) {
          this.$store.commit('searchFilters/setRayon', this.rayonSearch)
        }
      },
      gdnSearch(newValue, old) {
        if (newValue !== old) {
          this.$store.commit('searchFilters/setGDN', this.gdnSearch)
        }
      }
    },
    methods: {
      ...mapActions('npasupplier', ['fetchSuppliersInfos']),
      handleClickOutside(evt) {
        if (evt.target.className !== 'search-suppliers-focus') {
          this.focused = false
        }
      },
      async setSearch() {
        await this.fetchDatas()
      },
      setIsActivated(val) {
        this.isActivated = val
      },
      async fetchDatas() {
        try {
          this.showLoader = true

          await this.fetchSuppliersInfos({
            search: this.supplierSearch,
            rayon: this.rayonSearch,
            uid: this.userRoles?.includes('manager') ? '' : this.userInfos.uid,
            email: '',
            year: this.year,
            gdn: this.gdnSearch
          })
        } finally {
          this.showLoader = false
        }
      },
      closeModal() {
        this.showModal = false
        this.editMode = false
        this.selectedElement = {}
        this.fetchDatas()
      },
      openEditModal(element) {
        this.selectedElement = element
        this.showModal = true
        this.editMode = true
      },
      openAddModal() {
        this.showModal = true
        this.editMode = false
      },
      async exportSupplierInfos() {
        let dataForExcel = []

        const today = new Date(Date.now()).toLocaleString()?.split(',')[0].replace(/\//g, '')
        const fileName = today + this.$t('INFOS.FILE_NAME')
        this.suppliersInfos.forEach(row => {
          dataForExcel.push(Object.values(row))
        })

        let reportData = {
          title: fileName,
          data: dataForExcel,
          headers: [
            'Année',
            'Code Rayon',
            'CNUF de Paiement',
            'Libellé CNUF de Paiement',
            'GDN',
            'Libellé de GDN',
            'Libellé de Rayon',
            'Acheteur(se)',
            'Assistant(e)s',
            'Email'
          ]
        }

        exportXlsSuppliersInfos(reportData)
      }
    },
    mounted() {
      this.supplierSearch = this.cnuf
      this.gdnSearch = this.gdn
      this.rayonSearch = this.rayon
      document.addEventListener('click', this.handleClickOutside)
      if (this.year) this.fetchDatas()
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside)
    }
  }
</script>
<style scoped lang="scss">
  @import '~@/styles/_colors';

  .main-container {
    margin: 0 2rem;
  }

  .results {
    border-bottom: 1px solid #009688;
    padding-left: 19px;
    font-size: 20px;
    font-weight: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .add {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 999;
  }

  .search {
    border-radius: 0.8rem 0.8rem 0 0;
    position: relative;
  }

  .search {
    display: flex;
    width: 100%;
    justify-content: space-between;

    input {
      background-color: #ffffffe1;
      border: 0;
      color: #000000;
      height: 44px;
      outline: none;
      width: 100%;
      font-size: 100%;
      border-radius: 8px 8px 0px 0px;
      padding: 1em 0 0.3em 0.7em;
      margin-right: 2em;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(2, 2, 2, 0.1);

      &::placeholder {
        color: darkgray;
      }
    }
  }

  .search-rayon:focus,
  .search-paymentlabel:focus,
  .search-suppliers:focus {
    border-bottom: 2px solid #009688 !important;
  }
</style>
